import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Card, Dropdown} from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import StoreTableHeader from './StoreTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useQuery from "../../hooks/useQuery";
import {api} from "../../utils/api";
import {toast} from "react-toastify";
import {CustomSpinner, formatDate} from "../../helpers/utils";
import AppContext from "../../context/Context";
import debounce from "lodash/debounce";
import axios from "axios";
import Swal from "sweetalert2";

const Store = () => {
    const [loading, setLoading] = useState(false)
    const [storeData, setStoreData] = useState([])
    const [count, setCount] = useState(0)
    const [length, setLength] = useState(0)
    const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    let query = useQuery()
    const {
        config: {isAuthenticated},
        setConfig
    } = useContext(AppContext);

    let cancelTokenSource = null;
    const fetchStores = async (query) => {
        setLoading(true)
        const sortingParam = `${sortingDirection === 'desc' ? '-' : ''}${sortingField}`;

        // Cancel the previous request if it exists
        if (cancelTokenSource) {
            cancelTokenSource.cancel("New request triggered");
        }

        // Create a new cancel token source
        cancelTokenSource = axios.CancelToken.source();
        await api.get(`/store/store/?${query.toString()}`, {
            params: {
                ordering: sortingParam,
            },
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                setStoreData(res.data?.results)
                setCount(res.data?.count)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    setConfig("isAuthenticated", !isAuthenticated)
                    setConfig("user", JSON.stringify({}))
                }
            })
        setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchStores, 600), []);


    useEffect(() => {
        fetchStores(query)
        setLength(storeData.length)
        // eslint-disable-next-line
    }, [sortingField, sortingDirection])

    useEffect(() => {
        return () => {
            if (cancelTokenSource) {
                cancelTokenSource.cancel("Component unmounted");
            }
        };
    }, [cancelTokenSource]);

    const columns = [
        {
            accessor: 'name',
            Header: 'Name',
            headerProps: {className: 'ps-5'},
            cellProps: {
                className: 'ps-5'
            },
            canSort: true,
            sortingDirection: sortingField === "name" && sortingDirection,
            isSorted: false,
        },
        {
            accessor: 'address',
            Header: 'Address',
            headerProps: {className: 'ps-5'},
            cellProps: {
                className: 'ps-5'
            },
            canSort: true,
            sortingDirection: sortingField === "address" && sortingDirection,
            isSorted: false,
        },
        {
            accessor: 'phone',
            Header: 'Phone',
            headerProps: {className: 'ps-5'},
            cellProps: {className: 'ps-5'},
            canSort: true,
            sortingDirection: sortingField === "phone" && sortingDirection,
            isSorted: false,
        },
        {
            accessor: 'commission',
            Header: 'Commission',
            headerProps: {className: 'ps-5'},
            cellProps: {className: 'ps-5'},
            canSort: true,
            sortingDirection: sortingField === "commission" && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {commission} = rowData.row.original;
                return (
                    commission?.map(c => (
                        <ul>
                            <li>from ${c.formatted_amount_from ? c.formatted_amount_from : 0} to
                                ${c.formatted_amount_to ? c.formatted_amount_to : 0}: &nbsp;
                                {!c.is_flat ? c.formatted_percentage + "%" : "$" + c.formatted_commission}
                            </li>
                        </ul>
                    ))
                )
            }
        },
        {
            accessor: 'created_at',
            Header: 'Creation Date',
            headerProps: {className: 'ps-5'},
            cellProps: {className: 'ps-5'},
            canSort: true,
            sortingDirection: sortingField === "created_at" && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {created_at} = rowData.row.original;
                return (
                    formatDate(created_at)
                )
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const {id, name} = rowData.row.original;
                const handleDelete = async (e) => {
                    const {isConfirmed} = await Swal.fire({
                        title: 'Are you sure?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                    })
                    if (isConfirmed) {
                        e.preventDefault()
                        await api.delete(`/store/store/${id}/`)
                            .then(() => {
                                toast.success(`Store ${name} successfully deleted.`, {
                                    theme: 'colored'
                                });
                                fetchStores(query)
                            })
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            <Dropdown.Item href={`/store/${id}/edit`}>Edit</Dropdown.Item>
                            <Dropdown.Item onClick={(e => handleDelete(e))}>Delete</Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const data = storeData && storeData.map(customer => (
        {
            id: customer.id,
            name: customer.name,
            address: customer.address,
            phone: customer.phone,
            commission: customer.commissions,
            created_at: customer.created_at,
        }
    ))

    const handleSort = (column) => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={data}
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <StoreTableHeader count={count} table fetchStores={delayedLoadItems}/>
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ?
                        <CustomSpinner
                            imageStyle={{top: 40, left: 30}}
                            width={180}
                        />
                        :
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    }
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchStores}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Store;
