import PropTypes from 'prop-types';
import React, {forwardRef, useEffect, useState} from 'react';
import {Col, Container, Form, Row} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {api} from "../../utils/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import useQuery from "../../hooks/useQuery";
import axios from "axios";
import IconButton from "../../components/common/IconButton";

const ReportTableHeader = ({fetchReports, count, loading, setIsPrinting, delayedLoadItems}) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const [startDate, setStartDate] = useState(yesterday);
    const [endDate, setEndDate] = useState(yesterday);
    const [customerValue, setCustomerValue] = useState(null)
    const [customerData, setCustomerData] = useState([])
    const [, setSelectedDates] = useState([startDate, endDate]);
    const [customerLoading, setCustomerLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    const navigate = useNavigate()
    let query = useQuery()
    let customerQuery = useQuery()


    let cancelTokenSource = null;
    const fetchCustomers = async () => {
        setCustomerLoading(true)
        // Cancel the previous request if it exists
        if (cancelTokenSource) {
            cancelTokenSource.cancel("New request triggered");
        }

        // Create a new cancel token source
        cancelTokenSource = axios.CancelToken.source();
        customerQuery.set("page_size", "50")
        customerQuery.set("page", currentPage)
        const data = (await api.get(`/customer/customer/?${customerQuery.toString()}`, {
            cancelToken: cancelTokenSource.token
        })).data
        setCustomerData([...customerData, ...data.results])
        setCustomerLoading(false)
    }

    const fetchSearchCustomers = async () => {
        setCustomerLoading(true)
        // Cancel the previous request if it exists
        if (cancelTokenSource) {
            cancelTokenSource.cancel("New request triggered");
        }

        // Create a new cancel token source
        cancelTokenSource = axios.CancelToken.source();
        const data = (await api.get(`/customer/customer/?${customerQuery.toString()}`, {
            cancelToken: cancelTokenSource.token
        })).data
        setCustomerData(data.results)
        setCustomerLoading(false)
    }

    useEffect(() => {
        return () => {
            if (cancelTokenSource) {
                cancelTokenSource.cancel("Component unmounted");
            }
        };
    }, [cancelTokenSource]);


    const onChange = dates => {
        query.delete("created_at")
        setSelectedDates(dates);
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        const formattedStartDate = start && new Date(start.getTime() - start.getTimezoneOffset() * 60000).toISOString().split('T')[0]; // Format start date as YYYY-MM-DD
        const formattedEndDate = end && new Date(end.getTime() - end.getTimezoneOffset() * 60000).toISOString().split('T')[0]; // Format end date as YYYY-MM-DD

        formattedStartDate && query.set("created_at__gte", formattedStartDate);
        formattedEndDate && query.set("created_at__lte", formattedEndDate);
        navigate(`?${query.toString()}`)
        delayedLoadItems(query)
    };

    useEffect(() => {
        if (!query.get("company")) {
            const formattedStartDate = startDate && new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split('T')[0]; // Format start date as YYYY-MM-DD
            const formattedEndDate = endDate && new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().split('T')[0]; // Format end date as YYYY-MM-DD

            formattedStartDate && query.set("created_at__gte", formattedStartDate);
            formattedEndDate && query.set("created_at__lte", formattedEndDate);
            navigate(`?${query.toString()}`)
            delayedLoadItems(query)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        fetchCustomers()
        // eslint-disable-next-line
    }, [currentPage])

    let customerOptions;

    customerOptions = customerData.map((customer) => ({
        value: customer.id,
        label: customer.fullname
    }));

// Add the reset option
    customerOptions = [
        {value: "", label: "------------------"},
        ...customerOptions
    ];

    const handleClear = () => {
        setSelectedDates([null, null]);
        onChange([null, null]);
        query.delete("created_at__gte")
        query.delete("created_at__lte")
        navigate(`?${query.toString()}`)
        fetchReports(query)
    };

    const CustomDateRange = forwardRef(({value, onClick}, ref) => (
        <div className="position-relative m-2">
            <Form.Control
                ref={ref}
                onClick={onClick}
                value={value}
                placeholder="Select a date range"
                className="ps-4"
                onChange={() => {
                }}
            />
            {value &&
                <FontAwesomeIcon icon={faTimes} onClick={handleClear} cursor={"pointer"} size={"sm"} color={"#364b90"}
                                 className="position-absolute end-0 top-50 translate-middle-y me-3"
                />
            }
        </div>
    ));

    return (
        <Container fluid>
            <Row className="align-items-center justify-content-between">
                <Col xs={12} lg={2} md={12} className="text-start mb-2 mt-2">
                    <h5 className="fs-0 mb-0 text-nowrap">Reports ({count} total)</h5>
                </Col>
                <Col sm={12} md={12} lg={5} className="text-start ps-2 pe-2">
                    <DatePicker
                        onChange={onChange}
                        placeholderText="Select a date range"
                        startDate={query.get("created_at__gte") && startDate}
                        formatWeekDay={(day) => day.slice(0, 3)}
                        endDate={query.get("created_at__lte") && endDate}
                        selectsRange
                        dateFormat="MMM dd, yyyy"
                        className="form-control m-2"
                        customInput={<CustomDateRange/>}
                    />
                </Col>
                <Col sm={12} md={12} lg={4} className="text-start ps-2 pe-2 d-print-none">
                    <Select
                        options={customerOptions}
                        name={'customer'}
                        placeholder={'Select a customer'}
                        value={customerValue}
                        isLoading={customerLoading}
                        className="m-2"
                        onMenuScrollToBottom={(e) => {
                            console.log(e)
                            setCurrentPage(page => page + 1)
                        }}
                        onInputChange={(e) => {
                            customerQuery.set('search', e);
                            fetchSearchCustomers();
                        }}
                        onChange={(value) => {
                            setCustomerValue(value);
                            query.set('customer_id', value.value);
                            navigate(`?${query.toString()}`);
                            fetchReports(query);
                        }}
                    />
                </Col>
                <Col sm={12} md={1} className="text-start pe-0 me-0 d-print-none">
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="print"
                        transform="shrink-3"
                        className='me-0 '
                        disabled={loading}
                        onClick={() => {
                            setIsPrinting(true)
                            query.set("page_size", count);
                            fetchReports(query, true);
                        }}
                    >
                        <span className="d-none d-xxl-inline-block ms-1">Print</span>
                    </IconButton>
                </Col>
            </Row>
        </Container>
    );
};

ReportTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default ReportTableHeader;
