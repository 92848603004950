import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from "react";
import {Card, Col, Form, Row, Button, Modal, Container} from "react-bootstrap";
import {Formik} from "formik";
import Flex from "../../../components/common/Flex";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import FormError from "../../errors/FormError";
import Select from "react-select";
import useQuery from "../../../hooks/useQuery";
import AppContext from "../../../context/Context";
import {CustomSpinner} from "../../../helpers/utils";
import Webcam from "react-webcam";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import IconButton from "../../../components/common/IconButton";
import Dropzone from "react-dropzone";
import DatePicker from "react-datepicker";

const NewCustomer = () => {
    const [avatarImage, setAvatarImage] = useState('');
    const [driverLicenceImage, setDriverLicenceImage] = useState('');
    const [cameraAvatarImageModal, setCameraAvatarImageModal] = useState(false);
    const [cameraDriverLicenceImageModal, setCameraDriverLicenceImageModal] = useState(false);
    const [storeValue, setStoreValue] = useState(null);
    const [driverLicenceIndex, setDriverLicenceIndex] = useState(0);
    const [avatarIndex, setAvatarIndex] = useState(0);
    const [loading, setLoading] = useState(false)
    const [avatarFiles, setAvatarFiles] = useState([]);
    const [driverLicenceFiles, setDriverLicenceFiles] = useState([]);
    const [cameraDevices, setCameraDevices] = useState([]);
    const [cameraAvatarFacingMode, setCameraAvatarFacingMode] = useState('environment');
    const [cameraDriverLicenceFacingMode, setCameraDriverLicenceFacingMode] = useState('environment');
    const [selectedAvatarDeviceId, setSelectedAvatarDeviceId] = useState("");
    const [selectedDriverLicenceDeviceId, setSelectedDriverLicenceDeviceId] = useState("");
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        phone: "",
        address: "",
        birth_date: "",
        credential_id: "",
        store: "",
        avatar: [],
        driver_licences: []
    })
    const [errors, setErrors] = useState({})
    const [stores, setStores] = useState([])
    const [date, setDate] = useState(null);
    const navigate = useNavigate()
    let query = useQuery()

    const {
        config: {group, avatarCustomerCameraId, driverLicenceCustomerCameraId, user_id},
        setConfig
    } = useContext(AppContext);

    useEffect(() => {
        const getCameraDevices = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const cameras = devices.filter((device) => device.kind === 'videoinput');
                setCameraDevices(cameras);
                setSelectedAvatarDeviceId(avatarCustomerCameraId ? avatarCustomerCameraId : cameras[0]?.deviceId || '');
                setSelectedDriverLicenceDeviceId(driverLicenceCustomerCameraId ? driverLicenceCustomerCameraId : cameras[0]?.deviceId || '');
            } catch (error) {
                console.error('Error enumerating media devices:', error);
            }

        };

        getCameraDevices();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setConfig("avatarCustomerCameraId", selectedAvatarDeviceId)
        setConfig("driverLicenceCustomerCameraId", selectedDriverLicenceDeviceId)
        // eslint-disable-next-line
    }, [selectedAvatarDeviceId, selectedDriverLicenceDeviceId])

    const videoDriverLicenceConstraints = {
        width: 2650,
        height: 1440,
        deviceId: driverLicenceCustomerCameraId,
        facingMode: cameraDriverLicenceFacingMode,
    };
    const videoAvatarConstraints = {
        width: 2650,
        height: 1440,
        deviceId: avatarCustomerCameraId,
        facingMode: cameraAvatarFacingMode,
    };


    const avatarImageWebcamRef = useRef(null);
    const driverLicenceImageWebcamRef = useRef(null);

    const captureAvatarImage = useCallback(
        () => {
            const imageSrc = avatarImageWebcamRef.current.getScreenshot({width: 5120, height: 2880});
            setAvatarImage(imageSrc)
            setAvatarIndex(0)
        },

        [avatarImageWebcamRef]
    );

    const captureDriverLicenceImage = useCallback(
        () => {
            const imageSrc = driverLicenceImageWebcamRef.current.getScreenshot();
            setDriverLicenceImage(imageSrc)
            setDriverLicenceIndex(0)
        },

        [driverLicenceImageWebcamRef]
    );

    const changeAvatarCamera = async () => {
        if (cameraDevices.length < 2) {
            setCameraAvatarFacingMode((prevFacingMode) =>
                prevFacingMode === 'environment' ? 'user' : 'environment'
            );
        } else {
            const currentIndex = cameraDevices.findIndex((device) => device.deviceId === selectedAvatarDeviceId);
            let nextIndex;
            if (currentIndex === -1 || currentIndex === cameraDevices.length - 1) {
                nextIndex = 0; // Switch to the front camera if current device is not found or last device
            } else if (currentIndex === 0) {
                nextIndex = 1; // Switch to the back camera if current device is front
            } else {
                nextIndex = currentIndex + 1; // Switch to the next device in the list
            }
            setSelectedAvatarDeviceId(cameraDevices[nextIndex]?.deviceId || '')
            await api.patch(`/account/user/${user_id}/`, {
                customer_avatar_camera_id: cameraDevices[nextIndex]?.deviceId || ''
            })
                .then(res => {
                    setConfig("avatarCustomerCameraId", res.data?.customer_avatar_camera_id)
                })
        }
    };

    const changeDriverLicenceCamera = async () => {
        if (cameraDevices.length < 2) {
            setCameraDriverLicenceFacingMode((prevFacingMode) =>
                prevFacingMode === 'environment' ? 'user' : 'environment'
            );
        } else {
            const currentIndex = cameraDevices.findIndex((device) => device.deviceId === selectedDriverLicenceDeviceId);
            let nextIndex;
            if (currentIndex === -1 || currentIndex === cameraDevices.length - 1) {
                nextIndex = 0; // Switch to the front camera if current device is not found or last device
            } else if (currentIndex === 0) {
                nextIndex = 1; // Switch to the back camera if current device is front
            } else {
                nextIndex = currentIndex + 1; // Switch to the next device in the list
            }
            setSelectedDriverLicenceDeviceId(cameraDevices[nextIndex]?.deviceId || '')
            await api.patch(`/account/user/${user_id}/`, {
                customer_driver_license_camera_id: cameraDevices[nextIndex]?.deviceId || ''
            })
                .then(res => {
                    setConfig("driverLicenceCustomerCameraId", res.data?.customer_driver_license_camera_id)
                })
        }
    };

    const fetchStoreData = async () => {
        const data = (await api.get(`/store/store/?${query.toString()}`)).data
        setStores(data.results)
    }

    const handleFieldChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        fetchStoreData()
        // eslint-disable-next-line
    }, [])

    const storeOptions = stores.map(store => (
        {value: store.id, label: store.name}
    ))

    const onInputChange = (e) => {
        query.set("search", e)
        fetchStoreData()
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        api.defaults.headers["Content-Type"] = 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
        let customerFormData = new FormData()
        customerFormData.append("first_name", formData.first_name)
        customerFormData.append("last_name", formData.last_name)
        avatarFiles.forEach(avatar => {
            customerFormData.append("avatar", avatar)
        })
        customerFormData.append("credential_id", formData.credential_id)
        customerFormData.append("phone", formData.phone)
        customerFormData.append("address", formData.address)
        if (date)
            customerFormData.append("birth_date", date && new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0])
        customerFormData.append("store", formData.store)
        driverLicenceFiles.forEach(driverLicence =>
            customerFormData.append("driver_licences", driverLicence)
        )
        await api.post("/customer/customer/", customerFormData)
            .then(() => {
                toast.success(`Customer successfully added.`, {
                    theme: 'colored'
                });
                setLoading(false);
                navigate("/customer");
            })
            .catch(err => {
                setErrors(err.response.data)
                setLoading(false);
                toast.error(`Failed to add customer.`, {
                    theme: 'colored'
                });
            })
    }

    const handleCloseAvatarCameraModal = () => {
        setCameraAvatarImageModal(!cameraAvatarImageModal)
        setAvatarImage("")
    };
    const handleCaptureAvatarCamera = () => {
        const imageData = avatarImage;
        const byteString = atob(imageData.split(",")[1]);
        const mimeString = imageData.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const cameraFile = new File([ab], "camera-photo.jpeg", {type: mimeString});
        setAvatarFiles(prevState => [
            Object.assign(cameraFile, {
                preview: URL.createObjectURL(cameraFile)
            }),
            ...prevState
        ])
        setAvatarImage("")
        setCameraAvatarImageModal(false);
    };
    const handleCaptureDriverLicenceCamera = () => {
        const imageData = driverLicenceImage;
        const byteString = atob(imageData.split(",")[1]);
        const mimeString = imageData.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const cameraFile = new File([ab], "camera-photo.jpeg", {type: mimeString});
        setDriverLicenceFiles(prevState => [
            Object.assign(cameraFile, {
                preview: URL.createObjectURL(cameraFile)
            }),
            ...prevState,
        ])
        setDriverLicenceImage("")
        setCameraDriverLicenceImageModal(false);
    };

    const handleAvatarFileRemove = () => {
        const updatedFiles = avatarFiles.filter((file, index) => index !== avatarIndex);
        setAvatarFiles(updatedFiles);
        setAvatarIndex(Math.min(avatarIndex, updatedFiles.length - 1));
    };

    const handleDriverLicenceFileRemove = () => {
        const updatedFiles = driverLicenceFiles.filter((file, i) => i !== driverLicenceIndex);
        setDriverLicenceFiles(updatedFiles);
        setDriverLicenceIndex(Math.min(driverLicenceIndex, updatedFiles.length - 1));
    };

    const onDropOnAvatarDropzone = (acceptedFiles) => {
        setAvatarFiles(prevState => [
                ...acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                ),
                ...prevState
            ]
        )
    }

    const onDropOnDriverLicenceDropzone = (acceptedFiles) => {
        setDriverLicenceFiles(prevState => [
                ...acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                ),
                ...prevState,
            ]
        )
    }

    const handleAvatarSlideChange = (swiper) => {
        setAvatarIndex(swiper.activeIndex)
    };

    const handleDriverLicenceSlideChange = (swiper) => {
        setDriverLicenceIndex(swiper.activeIndex)
    };

    const handleCloseDriverLicenceCameraModal = () => {
        setCameraDriverLicenceImageModal(!cameraDriverLicenceImageModal)
        setDriverLicenceImage("")
    };
    const handleNumberChange = (e) => {
        const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        if (avatarImage !== '' && cameraAvatarImageModal)
            handleCaptureAvatarCamera()
        if (driverLicenceImage !== '' && cameraDriverLicenceImageModal)
            handleCaptureDriverLicenceCamera()
        // eslint-disable-next-line
    }, [avatarImage, driverLicenceImage])

    return (
        <Fragment>
            {loading ?
                <CustomSpinner
                    imageStyle={{top: 40, left: 30}}
                    width={180}
                    style={{zIndex: 9999}}
                /> :
                <Formik initialValues={{}} onSubmit={handleSubmit}>
                    <Form onSubmit={handleSubmit}>
                        <Card className={"mt-3"}>
                            <Card.Header className={"ms-4 me-4"}>
                                <Flex justifyContent={"between"}>
                                    <h3>Customer Information</h3>
                                    <Flex justifyContent={"between"} wrap={"wrap"}>
                                        <Form.Group className={"me-2"}>
                                            <Button
                                                type="reset"
                                                className={"bg-secondary"}
                                                onClick={() => navigate("/customer")}
                                            >
                                                Cancel
                                            </Button>
                                        </Form.Group>
                                        <Form.Group>
                                            {loading ?
                                                <CustomSpinner
                                                    imageStyle={{top: 40, left: 30}}
                                                    width={180}
                                                    style={{zIndex: 9999}}
                                                />
                                                :
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                >
                                                    Save
                                                </Button>
                                            }
                                        </Form.Group>
                                    </Flex>
                                </Flex>
                            </Card.Header>
                            <Card.Body className={"ms-4 me-4"}>
                                <Row className={"g-3"}>
                                    <Col md={6} lg={2}>
                                        <Col className="me-0">
                                            <Form.Label className="mt-1">Profile Image</Form.Label>
                                            <Dropzone onDrop={acceptedFiles => onDropOnAvatarDropzone(acceptedFiles)}>
                                                {({getRootProps, getInputProps}) => (
                                                    <>
                                                        {/* Avatar Files */}
                                                        {avatarFiles.length > 0 ? (
                                                            <Swiper
                                                                onSlideChange={handleAvatarSlideChange}
                                                                pagination={{clickable: true}}
                                                                navigation={true}
                                                                modules={[Pagination, Navigation]}
                                                                style={{height: "100%"}}
                                                            >
                                                                {avatarFiles.map((image, index) => (
                                                                    <SwiperSlide
                                                                        key={index}
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                            overflow: "hidden"
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={image.preview}
                                                                            className="d-block rounded-soft img-thumbnail shadow-sm"
                                                                            style={{
                                                                                objectFit: "cover",
                                                                                height: "250px",
                                                                                width: "250px",
                                                                                maxWidth: "250px",
                                                                                maxHeight: "250px"
                                                                            }}
                                                                            alt={image.customer_name}
                                                                        />
                                                                    </SwiperSlide>
                                                                ))}
                                                            </Swiper>
                                                        ) : (
                                                            <div {...getRootProps({className: "dropzone-area py-6"})}>
                                                                <input {...getInputProps()} disabled/>
                                                                <Flex justifyContent="center">
                                                                    <p className="fs-0 mb-0 text-700">Images preview in
                                                                        here</p>
                                                                </Flex>
                                                            </div>
                                                        )}

                                                        <FormError error={errors.avatar}/>

                                                        {/* Avatar Files Buttons */}
                                                        <Flex
                                                            justifyContent={avatarFiles.length > 0 ? "between" : "end"}
                                                            wrap="wrap" className="mt-4">
                                                            <div className="position-relative top-1">
                                                                {avatarFiles.length > 0 && (
                                                                    <IconButton
                                                                        variant="danger"
                                                                        size="sm"
                                                                        icon="times"
                                                                        className="me-1 mb-2"
                                                                        transform="shrink-3"
                                                                        onClick={handleAvatarFileRemove}
                                                                    >
                                                                    <span
                                                                        className="d-none d-xxl-inline-block ms-1">Remove</span>
                                                                    </IconButton>
                                                                )}
                                                            </div>
                                                            <div className="position-relative top-1">
                                                                <IconButton
                                                                    variant="success"
                                                                    transform="shrink-3"
                                                                    size="sm"
                                                                    icon="camera"
                                                                    onClick={() => setCameraAvatarImageModal(true)}
                                                                >
                                                                    <span className="d-none d-xxl-inline-block ms-1">Take Photo</span>
                                                                </IconButton>
                                                            </div>
                                                        </Flex>
                                                    </>
                                                )}
                                            </Dropzone>
                                        </Col>
                                    </Col>
                                    <Col md={1}></Col>
                                    <Col md={5} lg={3}>
                                        <Col className="me-0">
                                            <Form.Label className="mt-1">Driver License or Passport Photo</Form.Label>
                                            <Dropzone
                                                onDrop={acceptedFiles => onDropOnDriverLicenceDropzone(acceptedFiles)}>
                                                {({getRootProps, getInputProps}) => (
                                                    <>
                                                        {/* Driver License Files */}
                                                        {driverLicenceFiles.length > 0 ? (
                                                            <Swiper
                                                                onSlideChange={handleDriverLicenceSlideChange}
                                                                pagination={{clickable: true}}
                                                                navigation={true}
                                                                modules={[Pagination, Navigation]}
                                                                style={{height: "100%"}}
                                                            >
                                                                {driverLicenceFiles.map((image, index) => (
                                                                    <SwiperSlide
                                                                        key={index}
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            overflow: "hidden",
                                                                        }}
                                                                        className={"rounded-soft"}
                                                                    >
                                                                        <img
                                                                            src={image.preview}
                                                                            className={"d-block img-thumbnail rounded-soft"}
                                                                            style={{
                                                                                objectFit: "cover",
                                                                                height: "250px",
                                                                                maxWidth: "100%",
                                                                                minWidth: "150px",
                                                                                scale: "2"
                                                                            }}
                                                                            alt={image.customer_name}
                                                                        />
                                                                    </SwiperSlide>
                                                                ))}
                                                            </Swiper>
                                                        ) : (
                                                            <div {...getRootProps({className: "dropzone-area py-6"})}>
                                                                <input {...getInputProps()} disabled/>
                                                                <Flex justifyContent="center">
                                                                    <p className="fs-0 mb-0 text-700">Images preview in
                                                                        here</p>
                                                                </Flex>
                                                            </div>
                                                        )}

                                                        <FormError error={errors.driver_licences}/>

                                                        {/* Driver License Files Buttons */}
                                                        <Flex
                                                            justifyContent={driverLicenceFiles.length > 0 ? "between" : "end"}
                                                            wrap="wrap" className="mt-4">
                                                            <div className="position-relative top-1">
                                                                {driverLicenceFiles.length > 0 && (
                                                                    <IconButton
                                                                        variant="danger"
                                                                        icon="times"
                                                                        className="me-1 mb-2"
                                                                        onClick={handleDriverLicenceFileRemove}
                                                                        size="sm"
                                                                    >
                                                                    <span
                                                                        className="d-none d-xl-inline-block ms-1">Remove</span>
                                                                    </IconButton>
                                                                )}
                                                            </div>
                                                            <div className="position-relative top-1">
                                                                <IconButton
                                                                    variant="success"
                                                                    size="sm"
                                                                    icon="camera"
                                                                    transform="shrink-3"
                                                                    onClick={() => setCameraDriverLicenceImageModal(true)}
                                                                >
                                                                    <span className="d-none d-xl-inline-block ms-1">Take Photo</span>
                                                                </IconButton>
                                                            </div>
                                                        </Flex>
                                                    </>
                                                )}
                                            </Dropzone>
                                        </Col>
                                    </Col>
                                    <Col md={1}></Col>
                                    <Col lg={5} md={12}>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>First Name:</Form.Label>
                                                    <Form.Control
                                                        placeholder={"First Name"}
                                                        name={"first_name"}
                                                        type={"text"}
                                                        value={formData.first_name}
                                                        onChange={handleFieldChange}
                                                    />
                                                    <FormError error={errors.first_name}/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>Last Name:</Form.Label>
                                                    <Form.Control
                                                        placeholder={"Last Name"}
                                                        name={"last_name"}
                                                        type={"text"}
                                                        value={formData.last_name}
                                                        onChange={handleFieldChange}
                                                    />
                                                    <FormError error={errors.last_name}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>Phone:</Form.Label>
                                                    <Form.Control
                                                        placeholder={"(555) 555-5555"}
                                                        name={"phone"}
                                                        type={"text"}
                                                        value={formData.phone}
                                                        onChange={handleNumberChange}
                                                    />
                                                    <FormError error={errors.phone}/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>Date of Birth:</Form.Label>
                                                    <DatePicker
                                                        selected={date}
                                                        onChange={(date) => {
                                                            setDate(date)
                                                        }}
                                                        formatWeekDay={day => day.slice(0, 3)}
                                                        className='form-control'
                                                        dateFormat="MM-dd-yyyy"
                                                        placeholderText="MM-DD-YYYY"
                                                    />
                                                    <FormError error={errors.birth_date}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>Driver license or passport ID:</Form.Label>
                                                    <Form.Control
                                                        placeholder={"Driver license or passport ID"}
                                                        name={"credential_id"}
                                                        type={"text"}
                                                        value={formData.credential_id}
                                                        onChange={handleFieldChange}
                                                    />
                                                </Form.Group>
                                                <FormError error={errors.id}/>
                                            </Col>
                                            <Col md={6}>
                                                {group === process.env.REACT_APP_ADMIN_GROUP_NAME &&
                                                    <Row>
                                                        <Form.Group>
                                                            <Form.Label>Store:</Form.Label>
                                                            <Select
                                                                onInputChange={(e) => onInputChange(e)}
                                                                options={storeOptions}
                                                                placeholder='Select a store...'
                                                                classNamePrefix="react-select"
                                                                value={storeValue}
                                                                onChange={value => {
                                                                    setStoreValue(value)
                                                                    setFormData({...formData, store: value.value})
                                                                }}
                                                            />
                                                        </Form.Group>
                                                        <FormError error={errors.store}/>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Address:</Form.Label>
                                                    <Form.Control
                                                        placeholder={"Address"}
                                                        name={"address"}
                                                        type={"text"}
                                                        value={formData.address}
                                                        onChange={handleFieldChange}
                                                    />
                                                    <FormError error={errors.address}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Modal show={cameraAvatarImageModal}
                               onHide={handleCloseAvatarCameraModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Camera</Modal.Title>
                            </Modal.Header>
                            <Modal.Body style={{padding: "0 !important"}}>
                                <Container>
                                    <Flex justifyContent={"center"} wrap={"wrap"} alignItems={"center"}>
                                        {avatarImage === '' ?
                                            <Webcam
                                                audio={false}
                                                forceScreenshotSourceSize
                                                ref={avatarImageWebcamRef}
                                                screenshotFormat="image/jpeg"
                                                className={"mb-2 rounded-soft"}
                                                style={{
                                                    maxHeight: "250px", // Set maximum height to full viewport height
                                                    minHeight: "250px",
                                                    minWidth: "250px",
                                                    maxWidth: "250px", // Set maximum width to full viewport width
                                                    objectFit: "cover", // Maintain aspect ratio and fill available space
                                                }}
                                                videoConstraints={videoAvatarConstraints}
                                            /> : <img src={avatarImage} alt={""}/>}
                                        <Button variant="primary" onClick={changeAvatarCamera}
                                                className={"mx-2 ms-lg-3"}
                                        >
                                            <i
                                                className={"fa fa-camera-rotate fa-xl mt-3 mb-3"}
                                                style={{
                                                    color: "white",
                                                    cursor: "pointer",
                                                }}
                                            ></i><br/>Switch Camera
                                        </Button>
                                    </Flex>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <Flex justifyContent={"center"} alignItems={"center"} wrap={"wrap"}>
                                    <Button variant="secondary"
                                            className={"mx-3"}
                                            onClick={handleCloseAvatarCameraModal}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                captureAvatarImage()
                                            }}
                                    >
                                        Capture
                                    </Button>
                                </Flex>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={cameraDriverLicenceImageModal}
                               size={"lg"}
                               onHide={handleCloseDriverLicenceCameraModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Camera</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Container>
                                    <Flex justifyContent={"center"} wrap={"wrap"} alignItems={"center"}
                                          style={{padding: 0}}>
                                        {driverLicenceImage === '' ? (
                                            <Webcam
                                                audio={false}
                                                ref={driverLicenceImageWebcamRef}
                                                screenshotFormat="image/jpeg"
                                                className={"mx-3 rounded-soft"}
                                                forceScreenshotSourceSize
                                                style={{
                                                    maxHeight: "300px", // Set maximum height to full viewport height
                                                    minHeight: "300px",
                                                    minWidth: "300px",
                                                    maxWidth: "100vw",
                                                }}
                                                videoConstraints={videoDriverLicenceConstraints}
                                            />
                                        ) : (
                                            <img src={driverLicenceImage} alt=""/>
                                        )}
                                        <Button
                                            variant="primary"
                                            className={"mt-2"}
                                            onClick={changeDriverLicenceCamera}
                                        >
                                            <i
                                                className="fa fa-camera-rotate fa-xl mt-3 mb-3"
                                                style={{color: 'white', cursor: 'pointer'}}
                                            ></i>
                                            <br/>
                                            Switch Camera
                                        </Button>
                                    </Flex>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary"
                                        className={"mx-3"}
                                        onClick={handleCloseDriverLicenceCameraModal}
                                >
                                    Cancel
                                </Button>
                                <Button variant="primary"
                                        className={"me-3"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            captureDriverLicenceImage()
                                        }}
                                >
                                    Capture
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Form>
                </Formik>
            }
        </Fragment>
    )
}
export default NewCustomer