import React, {useContext, useEffect} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import is from 'is_js';
import AppContext from 'context/Context';
import FalconRoutes from 'routes';
import {CloseButton} from 'components/common/Toast';
import SettingsPanel from 'components/settings-panel/SettingsPanel';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import {api} from "./utils/api";

const App = () => {
    const HTMLClassList = document.getElementsByTagName('html')[0].classList;
    const {
        config: {navbarPosition, user_id},
        setConfig
    } = useContext(AppContext);

    const setCSRF = async () => {
        let csrfURL = "/account/setcsrf/";
        await api.get(csrfURL, {
            headers: {'Authorization': null},
            withCredentials: true,
        });
    }

    useEffect(() => {
        setCSRF();
    }, []);

    const getUser = async () => {
        await api.get(`/account/user/${user_id}/`)
            .then(res => {
                setConfig("avatar", res.data?.avatar);
                setConfig("avatarCustomerCameraId", res.data?.customer_avatar_camera_id);
                setConfig("driverLicenceCustomerCameraId", res.data?.customer_driver_license_camera_id);
                setConfig("newCheckCameraId", res.data?.check_camera_id);
                setConfig("printer_ip", res.data?.printer_ip);
            })
    }

    useEffect(() => {
        if (Number.isInteger(user_id)) {
            getUser();
        }
        // eslint-disable-next-line
    }, [])


    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add('windows');
        }
        if (is.chrome()) {
            HTMLClassList.add('chrome');
        }
        if (is.firefox()) {
            HTMLClassList.add('firefox');
        }
        if (is.safari()) {
            HTMLClassList.add('safari');
        }
        // eslint-disable-next-line
    }, [HTMLClassList]);

    useEffect(() => {
        if (navbarPosition === 'double-top') {
            HTMLClassList.add('double-top-nav-layout');
        }
        return () => HTMLClassList.remove('double-top-nav-layout');
    }, [navbarPosition, HTMLClassList]);

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <FalconRoutes/>
            <SettingsPanel/>
            <ToastContainer
                closeButton={CloseButton}
                icon={false}
                position={toast.POSITION.BOTTOM_LEFT}
            />
        </Router>
    );
};

export default App;
