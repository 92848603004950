/*eslint camelcase: ["error", {allow: ["status_negotiation", "sort_position"]}]*/
import React, {useEffect, useRef} from 'react';
import KanbanColumn from './KanbanColumn';
import {DragDropContext} from 'react-beautiful-dnd';
import is from 'is_js';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';

const KanbanContainer = ({
                             openTaskInformationModal,
                             setOpenTaskInformationModal,
                             setTaskID,
                             kanbanId,
                             kanbanItems,
                             setKanbanItems,
                             setSelectedTask,
                             onFilter,
                             filters
                         }) => {
    const containerRef = useRef(null);
    useEffect(() => {
        if (is.ipad()) {
            containerRef.current.classList.add('ipad');
        }

        if (is.mobile()) {
            containerRef.current.classList.add('mobile');
            if (is.safari()) {
                containerRef.current.classList.add('safari');
            }
            if (is.chrome()) {
                containerRef.current.classList.add('chrome');
            }
        }
    }, []);

    const getColumn = (id) => {
        return kanbanItems?.find((item) => item.id === Number(id));
    };

    const reorderArray = (array, fromIndex, toIndex) => {
        const newArr = [...array];
        const chosenItem = newArr.splice(fromIndex, 1)[0];
        newArr.splice(toIndex, 0, chosenItem);

        return newArr;
    };

    const move = (source, destination) => {
        const sourceItemsClone = [...getColumn(source.droppableId).elements];
        const destItemsClone = [...getColumn(destination.droppableId).elements];

        const [removedItem] = sourceItemsClone.splice(source.index, 1);
        destItemsClone.splice(destination.index, 0, removedItem);

        return {
            updatedDestItems: destItemsClone,
            updatedSourceItems: sourceItemsClone
        };
    };

    const handleDragEnd = async (result) => {
        const {source, destination} = result;
        if (!destination) {
            return;
        }
        if (source.droppableId === destination.droppableId) {
            const items = getColumn(source.droppableId).elements;
            const column = getColumn(source.droppableId);

            const reorderedItems = reorderArray(items, source.index, destination.index);
            try {
                setKanbanItems(
                    kanbanItems?.map((kanbanItem) =>
                        kanbanItem.id === column.id
                            ? {
                                ...kanbanItem,
                                elements: [...reorderedItems]
                            }
                            : kanbanItem
                    )
                );
                //await axios.post(`kanban/brand/${itemId}/position/`, { sort_position: destination.index });
            } catch {
                toast.error(`We found some problems when saving task data.`);
            }
        } else {
            const sourceColumn = getColumn(source.droppableId);
            const destColumn = getColumn(destination.droppableId);
            const movedItems = move(source, destination);
            try {
                setKanbanItems(
                    kanbanItems?.map((kanbanItem) =>
                        kanbanItem.id === sourceColumn.id || kanbanItem.id === destColumn.id
                            ? {
                                ...kanbanItem,
                                elements:
                                    (kanbanItem.id === sourceColumn.id && movedItems?.updatedSourceItems) ||
                                    (kanbanItem.id === destColumn.id && movedItems?.updatedDestItems)
                            }
                            : kanbanItem
                    )
                );
            } catch {
                toast.error(`We found some problems when saving task data.`);
            }
        }
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <div className="scrollbar w-100" ref={containerRef}>
                {kanbanItems?.map((kanbanColumnItem, index) => (
                    <KanbanColumn
                        index={index}
                        kanbanId={kanbanId}
                        setSelectedTask={setSelectedTask}
                        openTaskInformationModal={openTaskInformationModal}
                        setOpenTaskInformationModal={setOpenTaskInformationModal}
                        key={kanbanColumnItem.id}
                        kanbanColumnItem={kanbanColumnItem}
                        kanbanItems={kanbanItems}
                        setKanbanItems={setKanbanItems}
                        setTaskID={setTaskID}
                        onFilter={onFilter}
                        filters={filters}
                    />
                ))}
            </div>
        </DragDropContext>
    );
};
KanbanContainer.propTypes = {
    kanbanItems: PropTypes.any,
    kanbanId: PropTypes.any,
    selectedTask: PropTypes.any,
    openTaskInformationModal: PropTypes.any,
    setOpenTaskInformationModal: PropTypes.func,
    setKanbanItems: PropTypes.func,
    setSelectedTask: PropTypes.func,
    setTaskID: PropTypes.func,
    board: PropTypes.any,
    onFilter: PropTypes.func,

    filters: PropTypes.any
};
export default KanbanContainer;
