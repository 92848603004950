import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Col, Form, Row, Button} from 'react-bootstrap';
import classNames from 'classnames';

const AddAnotherForm = ({onSubmit: handleSubmit, type, showForm, setShowForm}) => {
    const [formData, setFormData] = useState({});
    const inputRef = useRef(null);

    useEffect(() => {
        if (showForm) {
            inputRef?.current?.focus();
        }
    }, [showForm]);

    return (
        <>
            {showForm && (
                <div
                    className={classNames('rounded-3 transition-none', {
                        'bg-100 p-card': type === 'list',
                        'p-3 border bg-white mt-3': type === 'card'
                    })}
                >
                    <Form>
                        <Row>

                            <Col className={"col-12"}>
                                <Form.Select
                                    className="mb-3"
                                    size="sm"
                                    onChange={({target}) => setFormData({...formData, type: target.value, unit: 20})}

                                >
                                    {[{value: '', label: "Choose a field"},
                                        {value: 'feed', label: "Feed"},
                                        {value: 'text', label: "Text"},
                                        {value: 'barcode', label: "Barcode"}].map(option => (
                                        <option
                                            key={option?.value}
                                            value={option?.value}
                                        >
                                            {option?.label}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                            {["text"].includes(formData?.type) && (<>
                                <Col className={"col-6"}>
                                    <Form.Select
                                        className="mb-3"
                                        size="sm"
                                        onChange={({target}) => setFormData({...formData, align: target.value})}

                                    >
                                        {[{value: '', label: "Align"},
                                            {value: 'left', label: "Left"},
                                            {value: 'center', label: "Center"},
                                            {value: 'right', label: "Right"}].map(option => (
                                            <option
                                                key={option?.value}
                                                value={option?.value}
                                            >
                                                {option?.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>

                                <Col className={"col-12"}>
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        className="mb-2"
                                        ref={inputRef}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault(); // Prevent default newline behavior
                                                handleSubmit(formData);
                                            }
                                        }}
                                        onChange={({target}) => setFormData({...formData, text: target.value})}
                                        placeholder={formData?.type === 'barcode' ? 'Enter the barcode...' : 'Enter a text...'}
                                    />
                                </Col></>)}
                            {["barcode"].includes(formData?.type) && (<>
                                <Col className={"col-6"}>
                                    <Form.Select
                                        className="mb-3"
                                        size="sm"
                                        onChange={({target}) => setFormData({...formData, barcode_type: target.value})}

                                    >
                                        {[
                                            {value: '', label: 'Type'},
                                            {value: 'upc_a', label: 'UPC-A'},
                                            {value: 'upc_e', label: 'UPC-E'},
                                            {value: 'ean13', label: 'EAN13'},
                                            {value: 'jan13', label: 'JAN13'},
                                            {value: 'ean8', label: 'EAN8'},
                                            {value: 'jan8', label: 'JAN8'},
                                            {value: 'code39', label: 'CODE39'},
                                            {value: 'itf', label: 'ITF'},
                                            {value: 'codabar', label: 'CODABAR'},
                                            {value: 'code93', label: 'CODE93'},
                                            {value: 'code128', label: 'CODE128'},
                                            {value: 'code128_auto', label: 'CODE128_Auto'},
                                            {value: 'gs1_128', label: 'GS1-128'},
                                            {
                                                value: 'gs1_databar_omnidirectional',
                                                label: 'GS1 DataBar Omnidirectional'
                                            },
                                            {value: 'gs1_databar_truncated', label: 'GS1 DataBar Truncated'},
                                            {value: 'gs1_databar_limited', label: 'GS1 DataBar Limited'},
                                            {value: 'gs1_databar_expanded', label: 'GS1 Databar Expanded'}
                                        ].map(option => (
                                            <option
                                                key={option?.value}
                                                value={option?.value}
                                            >
                                                {option?.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col className={"col-12"}>
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        className="mb-2"
                                        ref={inputRef}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault(); // Prevent default newline behavior
                                                handleSubmit(formData);
                                            }
                                        }}
                                        onChange={({target}) => setFormData({
                                            ...formData,
                                            hri: "none",
                                            text: target.value,
                                            height: 32,
                                            font: "font_a",
                                            width: 2
                                        })}
                                        placeholder={formData?.type === 'barcode' ? 'Enter the barcode...' : 'Enter a text...'}
                                    />
                                </Col></>)}

                        </Row>
                        <Row className="gx-2">
                            <Col>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        return handleSubmit(formData);
                                    }}
                                    variant="primary"
                                    size="sm"
                                    className="d-block w-100"
                                    type="button"
                                >
                                    Add
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    className="d-block w-100 border-400"
                                    type="button"
                                    onClick={() => setShowForm(false)}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}
        </>
    );
};

AddAnotherForm.propTypes = {
    onSubmit: PropTypes.func,
    type: PropTypes.string,
    showForm: PropTypes.bool,
    setShowForm: PropTypes.func
};

export default AddAnotherForm;
