import React from 'react';
import PropTypes from 'prop-types';
const KanbanColumnHeader = ({ title, itemCount }) => {
  return (
    <div className="kanban-column-header">
      <h5 className="fs-0 mb-0">
        {title}
      </h5>
    </div>
  );
};

KanbanColumnHeader.propTypes = {
  id: PropTypes.number.isRequired,
  kanbanItems: PropTypes.any,
  filters: PropTypes.any,
  setKanbanItems: PropTypes.func,
  onFilter: PropTypes.func,
  title: PropTypes.string.isRequired,
  itemCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default KanbanColumnHeader;
