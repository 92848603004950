import React  from 'react';
import KanbanContainer from './KanbanContainer';
import PropTypes from 'prop-types';

const Kanban = ({
  kanbanId,
  isLoading,
  filters,
  columns,
  setColumns,
  onFilter,
  board,
  setTaskID,
  selectedTask,
  setSelectedTask,
  openTaskInformationModal,
  setOpenTaskInformationModal
}) => {
  return (
    <>
      {columns && (
        <>


          {!isLoading && (
            <KanbanContainer
              kanbanId={kanbanId}
              setTaskID={setTaskID}
              board={board}
              onFilter={onFilter}
              filters={filters}
              kanbanItems={columns}
              setKanbanItems={setColumns}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
              openTaskInformationModal={openTaskInformationModal}
              setOpenTaskInformationModal={setOpenTaskInformationModal}
            />
          )}
        </>
      )}
    </>
  );
};
Kanban.propTypes = {
  members: PropTypes.array,
  filters: PropTypes.any,
  kanbanId: PropTypes.any,
  setTaskID: PropTypes.func,
  onSortBy: PropTypes.func,
  sortBy: PropTypes.any,
  clearFilter: PropTypes.any,
  onFilter: PropTypes.any,
  setOpenTaskInformationModal: PropTypes.func,
  openTaskInformationModal: PropTypes.any,
  selectedTask: PropTypes.any,
  setSelectedTask: PropTypes.func,
  setUserId: PropTypes.func,
  setColumns: PropTypes.func,
  board: PropTypes.any,
  isLoading: PropTypes.any,
  onSearch: PropTypes.func,
  search: PropTypes.string,
  columns: PropTypes.any
};
export default Kanban;
