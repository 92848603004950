import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import Flex from 'components/common/Flex';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ReportTableHeader from './ReportTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import {api} from "../../utils/api";
import useQuery from "../../hooks/useQuery";
import {CustomSpinner} from "../../helpers/utils";
import AppContext from "../../context/Context";
import axios from "axios";
import debounce from "lodash/debounce";
import "./PrintReport.css"

const Report = () => {
    const [loading, setLoading] = useState(false)
    const [reportData, setReportData] = useState([])
    const [totalAmount, setTotalAmount] = useState("")
    const [totalCommission, setTotalCommission] = useState("")
    const [totalNet, setTotalNet] = useState("")
    const [count, setCount] = useState(20)
    const [length, setLength] = useState(0)
    const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const [isPrinting, setIsPrinting] = useState(false)
    const [page, setPage] = useState(20)
    let query = useQuery()
    const {
        config: {isAuthenticated},
        setConfig
    } = useContext(AppContext);

    let cancelTokenSource = null;
    const fetchReports = async (query, forPrinting = false) => {
        setLoading(true)
        const sortingParam = `${sortingDirection === 'desc' ? '-' : ''}${sortingField}`;

        // Cancel the previous request if it exists
        if (cancelTokenSource) {
            cancelTokenSource.cancel("New request triggered");
        }

        // Create a new cancel token source
        cancelTokenSource = axios.CancelToken.source();
        await api.get(`/check/check/?${query.toString()}`, {
            params: {
                ordering: sortingParam,
            },
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                setReportData(res.data?.results)
                setTotalAmount(res.data?.total_amount)
                setTotalNet(res.data?.net_total)
                setTotalCommission(res.data?.total_commission_amount)
                setCount(res.data?.count)
                if (forPrinting) {
                    setIsPrinting(true)
                    setPage(count)
                }
            })
            .catch(err => {
                if (err.response.status === 403) {
                    setConfig("isAuthenticated", !isAuthenticated)
                    setConfig("user", JSON.stringify({}))
                }
            })

        setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchReports, 600), []);

    useEffect(() => {
        fetchReports(query)
        setLength(reportData.length)
        // eslint-disable-next-line
    }, [sortingField, sortingDirection])

    useEffect(() => {
        if (isPrinting)
            delayedLoadItems(query)
        // eslint-disable-next-line
    }, [isPrinting])

    useEffect(() => {
        return () => {
            if (cancelTokenSource) {
                cancelTokenSource.cancel("Component unmounted");
            }
        };
    }, [cancelTokenSource]);

    useEffect(() => {
        const beforePrint = () => {
            setIsPrinting(true)
            console.log('We are about to print');
        };

        const afterPrint = () => {
            console.log('We have just printed');
            setPage(20);
            setIsPrinting(false)
        };

        window.addEventListener('beforeprint', beforePrint);
        window.addEventListener('afterprint', afterPrint);

        return () => {
            window.removeEventListener('beforeprint', beforePrint);
            window.removeEventListener('afterprint', afterPrint);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!loading && isPrinting && reportData)
            window.print()
    }, [loading, isPrinting, reportData])

    const columns = [
        {
            accessor: "empty",
            disableSortBy: true
        },
        {
            accessor: 'amount',
            Header: 'Amount',
            headerProps: {className: 'pe-1'},
            cellProps: {
                className: 'py-2'
            },
            canSort: true,
            sortingDirection: sortingField === "amount" && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {amount} = rowData.row.original;
                return (
                    <>${amount}</>
                )
            }
        },
        {
            accessor: 'commission',
            canSort: true,
            Header: 'Percentage',
            headerProps: {className: 'ps-5'},
            cellProps: {
                className: 'ps-5'
            },
            sortingDirection: sortingField === "commission" && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {commission} = rowData.row.original;
                return (
                    <>{commission ? commission : 0}%</>
                )
            }
        },
        {
            accessor: 'commission_amount',
            Header: 'Commission',
            canSort: false,
            headerProps: {className: 'ps-5'},
            cellProps: {
                className: 'ps-5'
            },
            Cell: rowData => {
                const {commission_amount} = rowData.row.original;
                return (
                    <>${commission_amount ? commission_amount : 0}</>
                )
            }
        },
        {
            accessor: 'net',
            Header: 'Net payment',
            headerProps: {className: 'ps-5'},
            cellProps: {className: 'ps-5'},
            canSort: false,
            Cell: rowData => {
                const {net} = rowData.row.original;
                return (
                    <>${net}</>
                )
            }
        },
        {
            accessor: 'customer',
            Header: 'Customer',
            headerProps: {className: 'ps-5'},
            cellProps: {className: 'ps-5'},
            canSort: true,
            sortingDirection: sortingField === "customer" && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {customer_id, customer} = rowData.row.original;
                return (
                    <Link to={`/customer/${customer_id}/detail`}>
                        <Flex alignItems="center">
                            <div className="flex-1">
                                <h5 className={`mb-0 fs--1`}>{customer}</h5>
                            </div>
                        </Flex>
                    </Link>
                );
            }
        }
    ];

    const data = reportData && reportData.map(customer => (
        {
            id: customer.id,
            cheque: customer.cheque,
            customer_id: customer.customer,
            amount: customer.formatted_amount,
            commission: customer.formatted_commission,
            commission_amount: customer.commission_amount,
            net: customer.net_payment,
            customer: customer.customer_name,
            last_customer_note: customer.last_customer_note,
        }
    ))

    const handleSort = (column) => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={data}
        >
            <Card className="mb-3">
                <Card.Header>
                    <ReportTableHeader setIsPrinting={setIsPrinting} table count={count} loading={loading}
                                       fetchReports={fetchReports} delayedLoadItems={delayedLoadItems}/>
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ?
                        <CustomSpinner
                            imageStyle={{top: 40, left: 30}}
                            width={180}
                        />
                        :
                        <>
                            <AdvanceTable
                                handleSort={handleSort}
                                table
                                extra={
                                    <>
                                        {totalAmount && totalNet && totalCommission &&
                                            <tr>
                                                <td><strong>Total</strong></td>
                                                <td><strong>${totalAmount}</strong></td>
                                                <td></td>
                                                <td className='ps-5'><strong>${totalCommission}</strong></td>
                                                <td className='ps-5'><strong>${totalNet}</strong></td>
                                                <td></td>
                                            </tr>
                                        }
                                    </>
                                }
                                totalAmount={totalAmount}
                                totalNet={totalNet}
                                totalCommission={totalCommission}
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    size: 'sm',
                                    striped: true,
                                    className: 'fs--1 mb-0 overflow-hidden print-content'
                                }}
                            />
                        </>
                    }
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchReports}
                        count={count}
                        length={length}
                        itemsPerPage={page}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Report;
