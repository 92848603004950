/*eslint camelcase: ["error", {allow: ["status_negotiation"]}]*/
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import KanbanColumnHeader from './KanbanColumnHeader';
import TaskCard from './TaskCard';
import AddAnotherForm from './AddAnotherForm';
import {Droppable} from 'react-beautiful-dnd';
import IconButton from 'components/common/IconButton';
import classNames from 'classnames';
import {toast} from 'react-toastify';
import './Receipt.css';
import Barcode from "react-barcode";

const KanbanColumn = ({
                          kanbanId,
                          kanbanColumnItem,
                          kanbanItems,
                          setKanbanItems,
                          setTaskID,
                          onFilter,
                          filters
                      }) => {
    const renderElement = (item) => {
        switch (item.type) {
            case 'text':
                return <div
                    className={`text-item text-${item.align === "left" ? "start" : item.align === "right" ? 'end' : 'center'}`}>{item.text}</div>;

            case 'feed':
                return <div className={`spacing spacing-${item.unit}`}>&zwnj;</div>;

            case 'barcode':
                // Placeholder for barcode rendering
                return <Barcode value={item?.text} options={{format: "CODE128"}}/>;

            default:
                return null;
        }
    };
    let {id, name} = kanbanColumnItem;
    const [showForm, setShowForm] = useState(false);
    const formViewRef = useRef(null);

    const handleSubmit = async (cardData) => {

        if (!Object.keys(cardData).length) {
            setShowForm(false);

            return;
        }
        try {
            setKanbanItems(kanbanItems.map(kanbanItem => ({
                ...kanbanItem,
                elements: [...kanbanItem.elements, cardData]
            })));
        } catch ({response: {data}}) {
            toast.error(`We found some problems when saving the task data.`);
        }

        setShowForm(false);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            formViewRef.current.scrollIntoView({behavior: 'smooth'});
        }, 500);

        return clearTimeout(timeout);
    }, [showForm]);

    return (<>
            <div className={classNames('kanban-column mt-2', {'form-added': showForm})} style={{width: "65%"}}>
                <KanbanColumnHeader
                    id={id}
                    title={name}
                    itemCount={kanbanColumnItem?.elements?.length}
                    kanbanItems={kanbanItems}
                    setKanbanItems={setKanbanItems}
                    onFilter={onFilter}
                    filters={filters}
                />
                <Droppable droppableId={`${id}`} type="KANBAN">
                    {(provided) => (
                        <>
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                id={`container-${id}`}
                                className="kanban-items-container scrollbar"
                            >
                                {kanbanColumnItem?.elements?.map((task, index) => (
                                    <TaskCard
                                        kanbanId={kanbanId}
                                        filters={filters}
                                        key={index}
                                        id={index}
                                        index={index}
                                        task={task}
                                        kanbanItems={kanbanItems}
                                        setKanbanItems={setKanbanItems}
                                        setTaskID={setTaskID}
                                    />
                                ))}
                                <AddAnotherForm onSubmit={handleSubmit} type="card" showForm={showForm}
                                                setShowForm={setShowForm}/>
                                {provided.placeholder}
                                <div ref={formViewRef}></div>
                            </div>
                            {!showForm && (
                                <div className="kanban-column-footer">
                                    <IconButton
                                        size="sm"
                                        variant="link"
                                        className="d-block w-100 btn-add-card text-decoration-none text-600"
                                        icon="plus"
                                        iconClassName="me-2"
                                        onClick={() => setShowForm(true)}
                                    >
                                        Add another line
                                    </IconButton>
                                </div>
                            )}

                        </>
                    )}
                </Droppable>
            </div>
            <div className={classNames('kanban-column w-32 mt-2', {'form-added': showForm})}>
                <KanbanColumnHeader
                    title={"Preview"}
                />
                <div className="kanban-items-container scrollbar">
                    {kanbanColumnItem?.elements?.map((task, index) => (
                        <React.Fragment key={index}>
                            {renderElement(task)}
                        </React.Fragment>
                    ))}
                </div>

            </div>
        </>
    );
};

KanbanColumn.propTypes = {
    kanbanColumnItem: PropTypes.any,
    filters: PropTypes.any,
    kanbanId: PropTypes.any,
    kanbanItems: PropTypes.any,
    setKanbanItems: PropTypes.func,
    setSelectedTask: PropTypes.func,
    setTaskID: PropTypes.func,
    onFilter: PropTypes.func,
    setOpenTaskInformationModal: PropTypes.func
};

export default KanbanColumn;
