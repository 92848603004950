import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Card, Dropdown} from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import UsersTableHeader from './UsersTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import useQuery from "../../hooks/useQuery";
import {api} from "../../utils/api";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Flex from "../../components/common/Flex";
import Avatar from "../../components/common/Avatar";
import {CustomSpinner, formatDate} from "../../helpers/utils";
import AppContext from "../../context/Context";
import debounce from "lodash/debounce";
import axios from "axios";
import Swal from "sweetalert2";

const Users = () => {
    const [loading, setLoading] = useState(false)
    const [usersData, setUsersData] = useState([])
    const [count, setCount] = useState(0)
    const [length, setLength] = useState(0)
    const [sortingField, setSortingField] = useState('date_joined'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    let query = useQuery()
    const {
        config: {isAuthenticated},
        setConfig
    } = useContext(AppContext);

    let cancelTokenSource = null;
    const fetchUsers = async (query) => {
        setLoading(true)
        const sortingParam = `${sortingDirection === 'desc' ? '-' : ''}${sortingField}`;

        // Cancel the previous request if it exists
        if (cancelTokenSource) {
            cancelTokenSource.cancel("New request triggered");
        }

        // Create a new cancel token source
        cancelTokenSource = axios.CancelToken.source();
        await api.get(`/account/user/?${query.toString()}`, {
            params: {
                ordering: sortingParam,
            },
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                setUsersData(res.data?.results)
                setCount(res.data?.count)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    setConfig("isAuthenticated", !isAuthenticated)
                    setConfig("user", JSON.stringify({}))
                }
            })
        setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchUsers, 600), []);

    useEffect(() => {
        fetchUsers(query)
        setLength(usersData.length)
        // eslint-disable-next-line
    }, [sortingField, sortingDirection])

    useEffect(() => {
        return () => {
            if (cancelTokenSource) {
                cancelTokenSource.cancel("Component unmounted");
            }
        };
    }, [cancelTokenSource]);

    const columns = [
        {
            accessor: 'first_name',
            Header: 'Name',
            headerProps: {className: 'ps-5'},
            cellProps: {
                className: 'ps-5'
            },
            canSort: true,
            sortingDirection: sortingField === "first_name" && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {first_name, avatar, id} = rowData.row.original;
                return (
                    <Link to={`/user/${id}/edit`}>
                        <Flex alignItems="center">
                            {avatar ? (
                                <>
                                    <Avatar src={avatar} size="xl" className="me-2"/>
                                    <div className="flex-1">
                                        <h5 className="mb-0 fs--1">{first_name}</h5>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <Avatar size="xl" className="me-2"/>
                                    <div className="flex-1">
                                        <h5 className="mb-0 fs--1">{first_name}</h5>
                                    </div>
                                </>
                            )}
                        </Flex>
                    </Link>
                );
            }
        },
        {
            accessor: 'groups',
            Header: 'Groups',
            headerProps: {className: 'ps-5'},
            cellProps: {
                className: 'ps-5'
            },
            canSort: true,
            sortingDirection: sortingField === "groups" && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {groups} = rowData.row.original;
                return (
                    <>
                        {groups.map(group => (
                            <>{group}<br/></>
                        ))}
                    </>
                )
            }
        },
        {
            accessor: 'store',
            Header: 'Store',
            headerProps: {className: 'ps-5'},
            cellProps: {className: 'ps-5'},
            canSort: true,
            sortingDirection: sortingField === "store" && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {store, store_id} = rowData.row.original;
                return (
                    <Link to={`/store/${store_id}/edit`}>
                        {store}
                    </Link>
                )
            }
        },
        {
            accessor: 'date_joined',
            Header: 'Joined Date',
            headerProps: {className: 'ps-5'},
            cellProps: {className: 'ps-5'},
            canSort: true,
            sortingDirection: sortingField === "date_joined" && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {date_joined} = rowData.row.original;
                return (
                    formatDate(date_joined)
                )
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const {id, first_name} = rowData.row.original;
                const handleDelete = async (e) => {
                    const {isConfirmed} = await Swal.fire({
                        title: 'Are you sure?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, delete it!'
                    })
                    if (isConfirmed) {
                        e.preventDefault()
                        await api.delete(`/account/user/${id}/`)
                            .then(() => {
                                toast.success(`User ${first_name} successfully deleted.`, {
                                    theme: 'colored'
                                });
                                fetchUsers(query)
                            })
                    }
                };
                return (
                    <CardDropdown>
                        <div className="py-2">
                            <Dropdown.Item href={`/user/${id}/edit`}>Edit</Dropdown.Item>
                            <Dropdown.Item onClick={(e => handleDelete(e))}>Delete</Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const data = usersData && usersData.map(user => (
        {
            id: user.id,
            first_name: user.full_name,
            avatar: user.avatar,
            groups: user.groups_names,
            store: user.store_name,
            store_id: user.store,
            date_joined: user.date_joined,
        }
    ))

    const handleSort = (column) => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={data}
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <UsersTableHeader count={count} table fetchUsers={delayedLoadItems}/>
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ?
                        <CustomSpinner
                            imageStyle={{top: 40, left: 30}}
                            width={180}
                        />
                        :
                        <AdvanceTable
                            handleSort={handleSort}
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    }
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchUsers}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Users;
