import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";
import useQuery from "../../hooks/useQuery";

const CustomersTableHeader = ({fetchCustomers, count}) => {
    const [searchInputValue, setSearchInputValue] = useState("")

    const navigate = useNavigate()

    let query = useQuery()


    const handleSubmit = (e) => {
        e.preventDefault()
        fetchCustomers(query)
    }

    return (
        <div className="d-flex flex-wrap">
            <div className={" mt-3 "}>
                <h5 className=" text-nowrap">Customers ({count} total )</h5>
            </div>
            <div className={"justify-content-end mt-2"}>
                <Form className="position-relative" onSubmit={(e) => handleSubmit(e)}>
                    <Form.Control
                        name={"search"}
                        type="search"
                        placeholder="Search..."
                        aria-label="Search"
                        className={`rounded-pill search-input`}
                        width={150}
                        style={{marginLeft: 10}}
                        autoFocus={true}
                        value={searchInputValue}
                        onChange={({target}) => {
                            setSearchInputValue(target.value)
                            query.set("search", target.value)
                            navigate(`?${query.toString()}`)
                            fetchCustomers(query)
                        }}
                    />
                </Form>
            </div>


            <div className="ms-auto text-end ps-0 mt-2">
                <div id="orders-actions">
                    <Link to={"/customer/new"}>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                        >

                            <span className="d-none d-sm-inline-block ms-1">New</span>


                        </IconButton>
                    </Link>
                </div>
            </div>
        </div>
    );
};

CustomersTableHeader.propTypes = {
    selectedRowIds: PropTypes.object,
    count: PropTypes.number
};

export default CustomersTableHeader;
