import React, {useEffect, useState} from 'react';
import Kanban from './LabelKanban/Kanban';
import {useParams} from 'react-router-dom';

const LabelKanban = ({store, setStore}) => {
    const {id} = useParams();

    const [openTaskInformationModal, setOpenTaskInformationModal] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [columns, setColumns] = useState([
        {
            "id": 1,
            "elements": store?.custom_receipt?.elements,
            "name": "Elements",
            "sort_position": 0
        }
    ]);
    useEffect(() => {
        let element = store;
        if (columns && columns[0] && columns[0].elements)
            element["custom_receipt"]["elements"] = columns[0].elements;
            setStore(element);
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    return (
        <div>
            <Kanban
                isLoading={false}
                kanbanId={id}
                columns={columns}
                setColumns={setColumns}
                setTaskID={() => {
                }}
                selectedTask={selectedTask}
                setSelectedTask={setSelectedTask}
                openTaskInformationModal={openTaskInformationModal}
                setOpenTaskInformationModal={setOpenTaskInformationModal}
            />
        </div>
    );
};

export default LabelKanban;
