import {faBuilding} from "@fortawesome/free-solid-svg-icons";

const storeGroupName = process.env.REACT_APP_STORE_GROUP_NAME
const adminGroupName = process.env.REACT_APP_ADMIN_GROUP_NAME
export const pagesRoutes = [
    {
        label: 'pages',
        children: [
            {
                name: 'Customers',
                icon: 'users',
                active: true,
                to: '/customer',
                groups: [adminGroupName, storeGroupName],
            },
            {
                name: 'Companies',
                to: '/company',
                groups: [adminGroupName, storeGroupName],
                active: true,
                icon: faBuilding,
            },
            {
                name: 'Reports',
                icon: "chart-pie",
                to: '/report',
                active: true,
                groups: [adminGroupName, storeGroupName]
            },
            {
                name: 'Stores',
                icon: "shopping-cart",
                to: '/store',
                active: true,
                groups: [adminGroupName]
            },
            {
                name: 'Accounts',
                icon: 'user',
                to: '/user',
                active: true,
                groups: [adminGroupName]
            },
        ]
    }
];

export default pagesRoutes
