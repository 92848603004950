import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import useQuery from "../../hooks/useQuery";

const CompaniesTableHeader = ({count, fetchCompanies}) => {
    const [searchInputValue, setSearchInputValue] = useState("")

    const navigate = useNavigate()
    let query = useQuery()

    const handleSubmit = (e) => {
        e.preventDefault()
        fetchCompanies(query)
    }

    return (
        <div className="d-flex flex-wrap">
            <div md={4}>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-2">Companies ({count} total)</h5>
            </div>
            <div>
                <Form className="position-relative" onSubmit={(e) => handleSubmit(e)}>
                    <Form.Control
                        name={"search"}
                        type="search"
                        placeholder="Search..."
                        aria-label="Search"
                        className={`rounded-pill search-input`}
                        width={150}
                        style={{marginLeft: 10}}
                        value={searchInputValue}
                        onChange={({target}) => {
                            setSearchInputValue(target.value)
                            query.set("search", target.value)
                            navigate(`?${query.toString()}`)
                            fetchCompanies(query)
                        }}
                    />
                </Form>
            </div>


        </div>
    );
};

CompaniesTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default CompaniesTableHeader;
