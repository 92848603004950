import PropTypes from 'prop-types';
import React, {useContext, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {toast} from 'react-toastify';
import {api} from "../../utils/api";
import AppContext from "../../context/Context";
import {Formik} from "formik";
import FormError from "../errors/FormError";

const LoginForm = ({hasLabel, layout}) => {
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        remember: false
    });

    const {
        config: {isAuthenticated},
        setConfig
    } = useContext(AppContext);

    // Handler
    const handleSubmit = async e => {
        e.preventDefault();
        await api.post("/account/login/", formData)
            .then(res => {
                setConfig("isAuthenticated", !isAuthenticated)
                setConfig("user_id", res.data?.user?.id);
                setConfig("avatar", res.data?.user?.avatar)
                setConfig("store_commission", res.data?.user?.store_commission)
                setConfig("printer_ip", res.data?.user?.printer_ip)
                setConfig("hey", 'hey')
                setConfig("group", res.data?.user.groups_names[0])

            })
            .catch(err => {
                setErrors(err.response.data)
                toast.error(`${err.response.data.non_field_errors}`, {
                    theme: 'colored'
                });
            })
    };

    const handleFieldChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Formik initialValues={{username: '', password: '', remember: false}} onSubmit={handleSubmit}>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Username</Form.Label>}
                    <Form.Control
                        placeholder={!hasLabel ? 'Username' : ''}
                        value={formData.username}
                        name="username"
                        onChange={handleFieldChange}
                        type="text"
                    />
                    <FormError error={errors.username}/>
                </Form.Group>

                <Form.Group className="mb-3">
                    {hasLabel && <Form.Label>Password</Form.Label>}
                    <Form.Control
                        placeholder={!hasLabel ? 'Password' : ''}
                        value={formData.password}
                        name="password"
                        onChange={handleFieldChange}
                        type="password"
                    />
                    <FormError error={errors.password}/>
                </Form.Group>

                <Row className="justify-content-between align-items-center">
                    <Col xs="auto">
                        <Form.Check type="checkbox" id="rememberMe" className="mb-0">
                            <Form.Check.Input
                                type="checkbox"
                                name="remember"
                                checked={formData.remember}
                                onChange={e =>
                                    setFormData({
                                        ...formData,
                                        remember: e.target.checked
                                    })
                                }
                            />
                            <Form.Check.Label className="mb-0 text-700">
                                Remember me
                            </Form.Check.Label>
                        </Form.Check>
                    </Col>

                    {/*<Col xs="auto">*/}
                    {/*    <Link*/}
                    {/*        className="fs--1 mb-0"*/}
                    {/*        to={`/forgot-password`}*/}
                    {/*    >*/}
                    {/*        Forgot Password?*/}
                    {/*    </Link>*/}
                    {/*</Col>*/}
                </Row>

                <Form.Group>
                    <Button
                        type="submit"
                        color="primary"
                        className="mt-3 w-100"
                        disabled={!formData.username || !formData.password}
                    >
                        Log in
                    </Button>
                </Form.Group>
            </Form>
        </Formik>
    );
};

LoginForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default LoginForm;
