import PropTypes from 'prop-types';
import React, {forwardRef, useState} from 'react';
import {Col, Container, Form, Row} from 'react-bootstrap';
import DatePicker from "react-datepicker";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import useQuery from "../../../hooks/useQuery";

const PrintReportTableHeader = ({count}) => {
    let query = useQuery()
    const [startDate] = useState(query.get("created_at__gte") ? new Date(query.get("created_at__gte")) : null);
    const [endDate] = useState(query.get("created_at__lte") ? new Date(query.get("created_at__lte")) : null);

    const CustomDateRange = forwardRef(({value, onClick}, ref) => (
        <div className="position-relative m-2">
            <Form.Control
                ref={ref}
                onClick={onClick}
                value={value}
                placeholder="Select a date range"
                className="ps-4"
                onChange={() => {
                }}
            />
            {value &&
                <FontAwesomeIcon icon={faTimes} cursor={"pointer"} size={"sm"} color={"#364b90"}
                                 className="position-absolute end-0 top-50 translate-middle-y me-3"
                />
            }
        </div>
    ));

    return (
        <Container fluid>
            <Row className="align-items-center justify-content-between">
                <Col xs={12} md={2} className="text-start mb-2 mt-2">
                    <h5 className="fs-0 mb-0 ">Reports ({count} total)</h5>
                </Col>
                <Col sm={12} md={4} className="text-start ps-2 pe-2">
                    <DatePicker
                        placeholderText="Select a date range"
                        startDate={startDate}
                        formatWeekDay={(day) => day.slice(0, 3)}
                        endDate={endDate}
                        selectsRange
                        dateFormat="MMM dd, yyyy"
                        className="form-control m-2"
                        customInput={<CustomDateRange/>}
                    />
                </Col>
            </Row>
        </Container>
    );
};

PrintReportTableHeader.propTypes = {
    selectedRowIds: PropTypes.object,
    count: PropTypes.number
};

export default PrintReportTableHeader;
