import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";
import useQuery from "../../hooks/useQuery";

const UsersTableHeader = ({count, fetchUsers}) => {
    const [searchInputValue, setSearchInputValue] = useState("")

    const navigate = useNavigate()
    let query = useQuery()

    const handleSubmit = (e) => {
        e.preventDefault()
        fetchUsers(query)
    }

    return (
        <div className="d-flex flex-wrap">
            <div>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-2">Accounts ({count} accounts)</h5>
            </div>
            <div>
                <Form className="position-relative" onSubmit={(e) => handleSubmit(e)}>
                    <Form.Control
                        name={"search"}
                        type="search"
                        placeholder="Search..."
                        aria-label="Search"
                        className={`rounded-pill search-input`}
                        width={150}
                        style={{marginLeft: 10}}
                        value={searchInputValue}
                        onChange={({target}) => {
                            setSearchInputValue(target.value)
                            query.set("search", target.value)
                            navigate(`?${query.toString()}`)
                            fetchUsers(query)
                        }}
                    />
                </Form>
            </div>

            <div className="ms-auto text-end ps-0">
                <div id="orders-actions">
                    <Link to={"/user/new"}>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="plus"
                            transform="shrink-3"
                        >

                            <span className="d-none d-sm-inline-block ms-1">New</span>


                        </IconButton>
                    </Link>
                </div>
            </div>
        </div>
    );
};

UsersTableHeader.propTypes = {
    selectedRowIds: PropTypes.object
};

export default UsersTableHeader;
