import React, {useContext} from 'react';
import LogoutContent from '../LogoutContent';

import AuthCardLayout from 'layouts/AuthCardLayout';
import AppContext from "../../../context/Context";
import {Navigate} from "react-router-dom";

const Logout = () => {
    const {
        config: {isAuthenticated},
    } = useContext(AppContext);
    if (isAuthenticated)
        return <Navigate to={"/customer"} replace/>
    return (
        <AuthCardLayout>
            <div className="text-center">
                <LogoutContent layout="card" titleTag="h3"/>
            </div>
        </AuthCardLayout>
    );
};

export default Logout;
