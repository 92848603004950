import axios from "axios";
import {getCookieValue} from "../helpers/utils";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "x-csrftoken"
});
api.defaults.headers.common['x-csrftoken'] = getCookieValue("csrftoken");

