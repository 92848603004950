import React, {Fragment, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import {Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import {Field, FieldArray, Formik} from "formik";
import FormError from "../../errors/FormError";
import {useParams} from "react-router";
import AppContext from "../../../context/Context";
import {CustomSpinner} from "../../../helpers/utils";
import IconButton from "../../../components/common/IconButton";
import FormErrors from "../../errors/FormErrors";
import {NumericFormat} from "react-number-format";
import LabelKanban from "../EditLabel";

const EditStore = () => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [checkedSwitches, setCheckedSwitches] = useState([])
    const [formData, setFormData] = useState({
        name: "",
        address: "",
        phone: "",
        commissions: [],
    })
    const {id} = useParams()
    const navigate = useNavigate()
    const {
        config: {isAuthenticated},
        setConfig
    } = useContext(AppContext);

    const getStoreInformation = async () => {
        setLoading(true)
        await api.get(`/store/store/${id}/`)
            .then(res => setFormData(res.data))
            .catch(err => {
                if (err.response.status === 404)
                    navigate("/errors/404")
                else if (err.response.status === 403) {
                    setConfig("isAuthenticated", !isAuthenticated)
                    setConfig("user", JSON.stringify({}))
                }
            })
        setLoading(false)
    }

    const handleSwitchToggle = (e, index) => {
        setIsSwitchOn(!isSwitchOn);
        if (e.target.checked)
            setCheckedSwitches(prevState => [
                ...prevState,
                index
            ])
        else
            setCheckedSwitches(checkedSwitches.filter(i => i !== index))
    };

    useEffect(() => {
        const newCheckedSwitches = [];
        formData.commissions.forEach((commission, index) => {
            if (commission.is_flat === true) {
                newCheckedSwitches.push(index);
            }
        });
        setCheckedSwitches(prevState => [
            ...prevState,
            ...newCheckedSwitches
        ])
    }, [formData.commissions]);


    const handleSubmit = async (e, values) => {
        e.preventDefault()
        setLoading(true)
        console.log(values)
        await api.patch(`/store/store/${id}/`, values)
            .then(() => {
                toast.success(`Store successfully updated.`, {
                    theme: 'colored'
                });
                setLoading(false);
                navigate("/store");
            })
            .catch(err => {
                setErrors(err.response.data)
                setFormData(values)
                setLoading(false);
                toast.error(`Failed to update store.`, {
                    theme: 'colored'
                });
            })
    }

    useEffect(() => {
        getStoreInformation()
        // eslint-disable-next-line
    }, [])
    const handleNumberChange = (e) => {
        const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    };

    return (
        <Fragment>
            {loading ?
                <CustomSpinner
                    imageStyle={{top: 40, left: 30}}
                    width={180}
                /> :
                <Card>
                    <Card.Header>
                        <h5>Store information</h5>
                    </Card.Header>
                    <Card.Body>
                        <Formik
                            initialValues={formData}
                            onSubmit={(values, {setSubmitting, setErrors}) => {
                                console.log(values)
                                handleSubmit(values)
                                    .then(response => {
                                        // Form submission succeeded. Do something.
                                    })
                                    .catch(error => {
                                        // Form submission failed. Don't clear the form data.
                                        setErrors({submit: 'There was an error submitting the form.'});
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                    });
                            }}
                        >
                            {({values, setFieldValue}) => (
                                <Form>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Name</Form.Label>
                                                <Field
                                                    name={"name"}
                                                    placeholder={"Name"}
                                                    className={"form-control"}
                                                />
                                                <FormError error={errors.name}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Address</Form.Label>
                                                <Field
                                                    name={"address"}
                                                    placeholder={"Address"}
                                                    className={"form-control"}
                                                />
                                                <FormError error={errors.address}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Phone</Form.Label>
                                                <Field name={"phone"}>
                                                    {({field, form}) => (
                                                        <Form.Control
                                                            placeholder="(555) 555-5555"
                                                            onChange={(e) => {
                                                                const formattedValue = handleNumberChange(e)
                                                                setFieldValue(field.name, formattedValue);
                                                            }}
                                                            type="text"
                                                            value={field.value}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors.phone}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className={"d-flex justify-content-center"}>
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Label>
                                                    <h5 className={"mt-3"}>Commission Structure</h5>
                                                </Form.Label>
                                                <FieldArray
                                                    name="commissions"
                                                    render={arrayHelpers => (
                                                        <>
                                                            {values.commissions && values.commissions.length > 0 ? (
                                                                values.commissions.map((commission, index) => (
                                                                    <Row key={index} className={"mt-3"}>
                                                                        <Col md={12} lg={2}>
                                                                            <Form.Group>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <Form.Label>Cost
                                                                                            From</Form.Label>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <InputGroup>
                                                                                            <InputGroup.Text
                                                                                                id="basic-addon1">$</InputGroup.Text>
                                                                                            <Field
                                                                                                name={`commissions.${index}.amount_from`}
                                                                                            >
                                                                                                {({
                                                                                                      field,
                                                                                                      form
                                                                                                  }) => (
                                                                                                    <NumericFormat
                                                                                                        name={`commissions.${index}.amount_from`}
                                                                                                        onChange={(e) => {
                                                                                                            setFieldValue(field.name, e.target.value);
                                                                                                        }}
                                                                                                        placeholder={"Cost From"}
                                                                                                        value={field.value}
                                                                                                        thousandSeparator={","}
                                                                                                        decimalScale={2}
                                                                                                        className={"form-control"}
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                        </InputGroup>
                                                                                        <FormErrors
                                                                                            errors={errors.commissions}
                                                                                            error={"amount_from"}
                                                                                            index={index}/>
                                                                                        <FormError
                                                                                            error={errors.amount_from}/>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={12} lg={2}>
                                                                            <Form.Group>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <Form.Label>Cost To</Form.Label>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col>
                                                                                        <InputGroup>
                                                                                            <InputGroup.Text
                                                                                                id="basic-addon1">$</InputGroup.Text>
                                                                                            <Field
                                                                                                name={`commissions.${index}.amount_to`}
                                                                                            >
                                                                                                {({
                                                                                                      field,
                                                                                                      form
                                                                                                  }) => (
                                                                                                    <NumericFormat
                                                                                                        name={`commissions.${index}.amount_to`}
                                                                                                        onChange={(e) => {
                                                                                                            setFieldValue(field.name, e.target.value);
                                                                                                        }}
                                                                                                        placeholder={"Cost To"}
                                                                                                        value={field.value}
                                                                                                        thousandSeparator={","}
                                                                                                        decimalScale={2}
                                                                                                        className={"form-control"}
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                        </InputGroup>
                                                                                        <FormErrors
                                                                                            errors={errors.commissions}
                                                                                            error={"amount_to"}
                                                                                            index={index}/>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={12} lg={4}>
                                                                            <Form.Group>
                                                                                <Row>
                                                                                    <Col xs="auto">
                                                                                        <Form.Label
                                                                                            className="text-end me-2">Rate:</Form.Label>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="d-flex flex-wrap">
                                                                                    <Col xs={7} md={5}>
                                                                                        <Form.Control plaintext readOnly
                                                                                                      defaultValue="% of check"/>
                                                                                    </Col>
                                                                                    <Col xs={3} md={3}>
                                                                                        <Field
                                                                                            name={`commissions.${index}.is_flat`}>
                                                                                            {({field, form}) => (
                                                                                                <Form.Switch
                                                                                                    checked={checkedSwitches.includes(index)}
                                                                                                    id="switch"
                                                                                                    className={"mt-1"}
                                                                                                    name={`commissions.${index}.is_flat`}
                                                                                                    onChange={(e) => {
                                                                                                        handleSwitchToggle(e, index)
                                                                                                        setFieldValue(field.name, e.target.checked);
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                    </Col>
                                                                                    <Col xs={7} md={4}>
                                                                                        <Form.Control plaintext readOnly
                                                                                                      defaultValue="Flat Rate"/>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={12} lg={3}>
                                                                            {!checkedSwitches.includes(index) ?
                                                                                <Form.Group>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Form.Label>Percentage</Form.Label>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <InputGroup>
                                                                                                <InputGroup.Text
                                                                                                    id="basic-addon1">%</InputGroup.Text>
                                                                                                <Field
                                                                                                    name={`commissions.${index}.percentage`}
                                                                                                >
                                                                                                    {({
                                                                                                          field,
                                                                                                          form
                                                                                                      }) => (
                                                                                                        <Form.Control
                                                                                                            type={"number"}
                                                                                                            min={0}
                                                                                                            max={100}
                                                                                                            defaultValue={0}
                                                                                                            aria-describedby={"percent-addon1"}
                                                                                                            name={`commissions.${index}.percentage`}
                                                                                                            onChange={(e) => {
                                                                                                                setFieldValue(field.name, e.target.value);
                                                                                                                if (e.target.value > 100)
                                                                                                                    setFieldValue(field.name, 100);
                                                                                                                if (e.target.value.startsWith("-"))
                                                                                                                    setFieldValue(field.name, 0);
                                                                                                            }}
                                                                                                            placeholder={"Percentage"}
                                                                                                            value={field.value}
                                                                                                            thousandSeparator={","}
                                                                                                            decimalScale={2}
                                                                                                            className={"form-control"}
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                            </InputGroup>
                                                                                            <FormErrors
                                                                                                errors={errors.commissions}
                                                                                                error={"percentage"}
                                                                                                index={index}/>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form.Group> :
                                                                                <Form.Group>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <Form.Label>Commission</Form.Label>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <InputGroup>
                                                                                                <InputGroup.Text
                                                                                                    id="basic-addon1">$</InputGroup.Text>
                                                                                                <Field
                                                                                                    name={`commissions.${index}.commission`}
                                                                                                    placeholder={"Commission"}
                                                                                                >
                                                                                                    {({
                                                                                                          field,
                                                                                                          form
                                                                                                      }) => (
                                                                                                        <NumericFormat
                                                                                                            placeholder={"Commission"}
                                                                                                            min={0}
                                                                                                            onChange={(e) => {
                                                                                                                setFieldValue(field.name, e.target.value);
                                                                                                            }}
                                                                                                            className={"form-control"}
                                                                                                            value={field.value}
                                                                                                            thousandSeparator={","}
                                                                                                            decimalScale={2}
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                            </InputGroup>
                                                                                            <FormErrors
                                                                                                errors={errors.commissions}
                                                                                                error={"commission"}
                                                                                                index={index}/>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Form.Group>
                                                                            }
                                                                        </Col>
                                                                        <Col md={1}>
                                                                            <Row>
                                                                                <Col>
                                                                                    <Form.Label></Form.Label>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col>
                                                                                    <IconButton
                                                                                        icon={"minus"}
                                                                                        iconClassName={"text-danger"}
                                                                                        className={"bg-white border-danger mt-0"}
                                                                                        style={{
                                                                                            width: "50px",
                                                                                            height: "50px",
                                                                                            borderRadius: "50%"
                                                                                        }}
                                                                                        type="button"
                                                                                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                                                                    >
                                                                                    </IconButton>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                ))
                                                            ) : (
                                                                ""
                                                            )}
                                                            <Row className={"d-flex flex-wrap justify-content-center"}>
                                                                <Col>
                                                                    <IconButton
                                                                        icon={"plus"}
                                                                        type="button"
                                                                        className={"mt-3 bg-white"}
                                                                        style={{
                                                                            width: "50px",
                                                                            height: "50px",
                                                                            borderRadius: "50%"
                                                                        }}
                                                                        iconClassName={"text-primary"}
                                                                        onClick={() => arrayHelpers.insert(values.commissions.length, '')} // insert an empty string at a position
                                                                    >
                                                                    </IconButton>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Col>
                                        {formData?.custom_receipt && <Col md={12}>
                                            <div className={"mt-3"}>
                                                <Form.Label>
                                                    <h5 className={"mt-3"}>Receipt Preview</h5>
                                                </Form.Label>
                                                <LabelKanban store={formData} setStore={setFormData}/>
                                            </div>
                                        </Col>}
                                    </Row>
                                    <Form.Group>
                                        <FormErrors errors={errors.commissions}/>
                                        {loading ?
                                            <CustomSpinner
                                                imageStyle={{top: 40, left: 30}}
                                                width={180}
                                            />
                                            :
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="mt-3 w-100"
                                                onClick={(e) => handleSubmit(e, values)}
                                            >
                                                Save
                                            </Button>
                                        }
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>

                    </Card.Body>
                </Card>
            }

        </Fragment>
    )
}

export default EditStore