export const buildEposXml = (data) => {
    // Create an XML document
    const xmlDoc = document.implementation.createDocument(null, "epos-print");
    xmlDoc.documentElement.setAttribute("xmlns", "http://www.epson-pos.com/schemas/2011/03/epos-print");


    // Get the root element
    const root = xmlDoc.documentElement;

    // Iterate over the elements array and build XML
    data?.elements?.forEach(element => {
        let el = xmlDoc.createElement(element.type);

        // Handle different element types
        switch (element.type) {
            case 'text':
                el.setAttribute('align', element.align);
                el.textContent = element.text; // Dynamic text content
                break;
            case "barcode":
                el.setAttribute('width', element?.width);
                el.setAttribute('font', element?.font);
                el.setAttribute('height', element?.height);
                el.setAttribute('type', element?.barcode_type);
                el.setAttribute('hri', element?.hri);

                el.textContent = element?.text; // Dynamic text content
                break;
            case 'feed':
                el.setAttribute('unit', element.unit); // Dynamic unit attribute
                break;
            default:
                break;

            // Add more cases for other types if needed
        }

        // Append the element to the root
        root.appendChild(el);
    });
    let el = xmlDoc.createElement("cut");
    root.appendChild(el);
    // Convert XML document to a string (optional, for demonstration)
    const serializer = new XMLSerializer();
    const xmlString = serializer.serializeToString(xmlDoc);

    return xmlString;
}
