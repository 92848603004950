import React, {useContext} from 'react';
import LoginForm from '../LoginForm';

import AuthCardLayout from 'layouts/AuthCardLayout';
import AppContext from "../../../context/Context";
import {Navigate} from "react-router-dom";

const Login = () => {
  const {
        config: {isAuthenticated},
    } = useContext(AppContext);
  if (isAuthenticated)
    return <Navigate to={"/customer"} replace />
  return (
    <AuthCardLayout
    >
      <h3>Account Login</h3>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default Login;
