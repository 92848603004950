import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import AppContext from "../../../context/Context";
import {api} from "../../../utils/api";
import defaultAvatar from 'assets/img/logos/user.png';

const ProfileDropdown = () => {

    const {
        config: {isAuthenticated, avatar},
        setConfig
    } = useContext(AppContext);

    const handleLogout = async () => {
        await api.post("/account/logout/")
        setConfig("isAuthenticated", !isAuthenticated)
        setConfig("avatar", "")
    }

    return (
        <Dropdown navbar={true} as="li">
            <Dropdown.Toggle
                bsPrefix="toggle"
                as={Link}
                to="#!"
                className="pe-0 ps-2 nav-link"
            >
                <Avatar src={avatar ? avatar : defaultAvatar}/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
                <div className="bg-white rounded-2 py-2 dark__bg-1000">
                    <Dropdown.Item as={Link} to="/settings">
                        Profile &amp; account
                    </Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Item as={Link} to="/logout" onClick={() => handleLogout()}>
                        Logout
                    </Dropdown.Item>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ProfileDropdown;
