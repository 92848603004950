import React, {Fragment, useCallback, useContext, useEffect, useRef, useState} from "react";
import AppContext from "../../../context/Context";
import {api} from "../../../utils/api";
import {useNavigate, useParams} from "react-router-dom";
import useQuery from "../../../hooks/useQuery";
import {toast} from "react-toastify";
import Select, {components} from "react-select";
import {CustomSpinner} from "../../../helpers/utils";
import {Button, Card, Col, Container, Form, InputGroup, Modal, Row} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import {Formik} from "formik";
import FormError from "../../errors/FormError";
import Dropzone from "react-dropzone";
import IconButton from "../../../components/common/IconButton";
import Webcam from "react-webcam";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import {NumericFormat} from "react-number-format";

const EditCheck = () => {
    const [frontImage, setFrontImage] = useState('');
    const [loading, setLoading] = useState(false)
    const [companyLoading, setCompanyLoading] = useState(false)
    const [cameraFrontImageModal, setCameraFrontImageModal] = useState(false)
    const [frontCheckFiles, setFrontCheckFiles] = useState([])
    const [netPayment, setNetPayment] = useState(0)
    const [rawAmount, setRawAmount] = useState(0)
    const [commission, setCommission] = useState(0)
    const [flatCommission, setFlatCommission] = useState(0)
    const [formErrors, setFormErrors] = useState({})
    const [customerData, setCustomerData] = useState({})
    const [companiesData, setCompaniesData] = useState([])
    const [companyValue, setCompanyValue] = useState()
    const [cameraDevices, setCameraDevices] = useState([]);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [date, setDate] = useState(null);
    const {
        config: {newCheckCameraId, user_id},
        setConfig
    } = useContext(AppContext);
    const [selectedDeviceId, setSelectedDeviceId] = useState("");
    const [companyForm, setCompanyForm] = useState(false)
    const [cameraFacingMode, setCameraFacingMode] = useState('environment');
    const [formData, setFormData] = useState({
        amount: "",
        commission: "",
        store: "",
        front_image: "",
        customer: "",
        company: "",
    })
    const [companyFormData, setCompanyFormData] = useState({
        company_name: "",
        company_address: "",
        company_phone: ""
    })

    const {id} = useParams()

    useEffect(() => {
        const getCameraDevices = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const cameras = devices.filter((device) => device.kind === 'videoinput');
                setCameraDevices(cameras);
                setSelectedDeviceId(newCheckCameraId ? newCheckCameraId : cameras[0]?.deviceId || '');
            } catch (error) {
                console.error('Error enumerating media devices:', error);
            }
        };

        getCameraDevices();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setConfig("newCheckCameraId", selectedDeviceId)
        // eslint-disable-next-line
    }, [selectedDeviceId])


    const videoConstraints = {
        width: 2650,
        height: 1440,
        deviceId: newCheckCameraId,
        facingMode: cameraFacingMode,
    };
    const frontImageWebcamRef = useRef(null);
    const captureFrontImage = useCallback(
        () => {
            const imageSrc = frontImageWebcamRef.current.getScreenshot({width: 5120, height: 2880});
            setFrontImage(imageSrc)
        },

        [frontImageWebcamRef]
    )

    const getCheckData = async () => {
        setLoading(true)
        await api.get(`/check/check/${id}/`)
            .then(async res => {
                setFormData(res.data)
                setCommission(res.data?.commission)
                setFlatCommission(res.data?.flat_commission)
                await api.get(`/customer/customer/${res.data?.customer}/`)
                    .then(response => setCustomerData(response.data))
                setFrontCheckFiles([
                    {
                        preview: res.data?.front_image
                    }
                ])
                if (res.data?.company)
                    setCompanyValue({
                        value: res.data?.company,
                        label: res.data?.company_label
                    })
                setDate(new Date(res.data?.created_at))
            })
            .catch(err => {
                if (err.response.status === 404 || err.response.status === 403)
                    navigate('/errors/404')
            })
        setLoading(false)
    }

    const changeCamera = async () => {
        if (cameraDevices.length < 2) {
            setCameraFacingMode((prevFacingMode) => {
                    return prevFacingMode === 'environment' ? 'user' : 'environment'
                }
            );
        } else {
            const currentIndex = cameraDevices.findIndex((device) => device.deviceId === selectedDeviceId);
            let nextIndex;
            if (currentIndex === -1 || currentIndex === cameraDevices.length - 1) {
                nextIndex = 0; // Switch to the front camera if current device is not found or last device
            } else if (currentIndex === 0) {
                nextIndex = 1; // Switch to the back camera if current device is front
            } else {
                nextIndex = currentIndex + 1; // Switch to the next device in the list
            }
            setSelectedDeviceId(cameraDevices[nextIndex]?.deviceId || '')
            await api.patch(`/account/user/${user_id}/`, {
                check_camera_id: cameraDevices[nextIndex]?.deviceId || ''
            })
                .then(res => {
                    setConfig("newCheckCameraId", res.data?.check_camera_id)
                })
        }
    };

    let query = useQuery()
    const navigate = useNavigate()

    const handleFieldChange = (e) => {
        if (e.target.value.startsWith("-"))
            e.target.value = 0;

        if (e.target.name === "commission" && e.target.value > 100)
            e.target.value = 100;

        if (e.target.name === "commission") {
            setCommission(e.target.value);
            const calculatedCommission = (formData.amount.replace(/[^0-9.]/g, '') * e.target.value.replace(/[^0-9.]/g, '')) / 100;
            setFlatCommission(calculatedCommission.toFixed(2).replace(".00", ""));
        }

        if (e.target.name === "flat_commission") {
            setFlatCommission(e.target.value);
            const calculatedCommission = (e.target.value.replace(/[^0-9.]/g, '') * 100) / formData.amount.replace(/[^0-9.]/g, '');
            setCommission(calculatedCommission.toFixed(2).replace(".00", ""));
        }

        if (e.target.name === "amount") {
            // Assuming store_commission is sorted in ascending order by amount_to
            setRawAmount(e.target.value.replace(/[^0-9.]/g, ''))
            const calculatedCommission = (rawAmount * commission) / 100;
            setFlatCommission(calculatedCommission.toFixed(2).replace(".00", ""));
            let rule = customerData?.store_commission?.filter(rule => parseFloat(rule.amount_from) <= e.target.value.replace(/[^0-9.]/g, '')).sort((a, b) => parseFloat(b.amount_to) - parseFloat(a.amount_to))[0];
            if (rule) {
                console.log(rule)
                if (rule.is_flat) {
                    // flat commission
                    setFlatCommission(rule.formatted_commission);
                    setCommission(((rule.commission * 100) / e.target.value.replace(/[^0-9.]/g, '')).toFixed(2).replace(".00", ""));
                } else {
                    // percentage commission
                    setCommission(parseFloat(rule.percentage));
                    const calculatedFlatCommission = (rule.percentage * e.target.value.replace(/[^0-9.]/g, '')) / 100;
                    setFlatCommission(calculatedFlatCommission.toFixed(2).replace(".00", ""));
                }
            }
        }

        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const fetchCompanies = async () => {
        setCompanyLoading(true)
        query.set("page_size", "50")
        const data = (await api.get(`/company/?${query.toString()}`)).data
        setCompaniesData(data.results)
        setCompanyLoading(false)
    }

    useEffect(() => {
        setNetPayment(formData.amount.replace(/[^0-9.]/g, '') - ((commission * formData.amount.replace(/[^0-9.]/g, '')) / 100))
    }, [commission, formData.amount])

    useEffect(() => {
        getCheckData()
        fetchCompanies()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (companyValue) {
            setCompanyFormData({})
            setCompanyForm(false)
        }
    }, [companyValue, companyForm, formErrors])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        let checkData = new FormData()
        console.log(companyFormData);
        if (frontCheckFiles[0] instanceof File)
            checkData.append("front_image", frontCheckFiles[0])
        checkData.append("amount", rawAmount)
        checkData.append("commission", commission)
        checkData.append("flat_commission", flatCommission)
        if (formData.company)
            checkData.append("company", formData?.company)
        checkData.append("company_name", companyFormData?.company_name ? companyFormData?.company_name : "")
        checkData.append("company_address", companyFormData?.company_address ? companyFormData?.company_address : "")
        checkData.append("company_phone", companyFormData?.company_phone ? companyFormData?.company_phone : "")
        if (date)
            checkData.append("created_at", date && date.toISOString())
        api.defaults.headers["Content-Type"] = 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
        await api.patch(`/check/check/${id}/`, checkData)
            .then(() => {
                setLoading(false)
                toast.success("Check successfully updated.", {theme: "colored"})
                navigate(`/customer/${customerData.id}/detail`)
            })
            .catch(err => {
                setFormErrors({...err.response.data, company: !companyForm ? err.response?.data?.company : ""})
                setLoading(false)
                toast.error("An error has occurred.", {theme: "colored"})
            })
    }

    const onDropOnFrontImageDropzone = (acceptedFiles) => {
        setFrontCheckFiles(
            acceptedFiles.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            )
        )
        setFormData({...formData, front_image: acceptedFiles[0]})
    }
    const handleFrontFileRemove = path => {
        setFrontCheckFiles(frontCheckFiles.filter(file => file.path !== path));
        setFrontImage("")
    }
    const handleCloseFrontCameraModal = () => {
        setCameraFrontImageModal(!cameraFrontImageModal)
        setFrontImage("")
    };
    const handleCaptureFrontCamera = () => {
        const imageData = frontImage;
        const byteString = atob(imageData.split(",")[1]);
        const mimeString = imageData.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const cameraFile = new File([ab], "camera-photo.jpeg", {type: mimeString});
        setFrontCheckFiles([
            Object.assign(cameraFile, {
                preview: URL.createObjectURL(cameraFile)
            })
        ])
        setFrontImage("")
        setCameraFrontImageModal(false);
    };
    let companyOptions = companiesData && companiesData.map((company) => (
        {label: company.address ? company.name + " - " + company.address : company.name, value: company.id}
    ))

    companyOptions = [
        {value: "", label: "----------------------"},
        ...companyOptions
    ]


    const handleCompanyInputChange = (e, actionMeta) => {
        if (actionMeta.action === 'input-change') {
            setCompanyFormData(prevState =>
                ({...prevState, company_name: e})
            );
        }
        query.set("search", e)
        fetchCompanies()
    };
    const handleAddNew = (e) => {
        setCompanyForm(true);
        setCompanyValue("")
        setFormData({...formData, company: ""})
        setFormErrors({...formErrors, company: ""})
        setMenuIsOpen(false);
    };

    const handleCompanyChange = (e) => {
        setCompanyFormData({...companyFormData, [e.target.name]: e.target.value})
    };
    const handleNumberChange = (e) => {
        const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        setCompanyFormData({
            ...companyFormData,
            [e.target.name]: e.target.value
        });
    };
    const Menu = (props) => {
        const menuHeaderStyle = {
            padding: '6px 12px',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            cursor: "pointer"
        };
        return (
            <Fragment>
                <components.Menu {...props}>
                    <div>
                        {props.selectProps.fetchingData ? (
                            <span className="fetching">Fetching data...</span>
                        ) : (
                            <div>{props.children}</div>
                        )}
                        <div className="option" aria-disabled="false"
                             tabIndex="-1" style={menuHeaderStyle} onClick={handleAddNew}>
                            <p className={"bol"} style={{color: "#9da9bb"}}>Add new</p>

                        </div>
                    </div>
                </components.Menu>
            </Fragment>
        );
    };

    const NoOptionsMessage = (props) => {
        return props.selectProps.inputValue ? (
            <components.NoOptionsMessage {...props} />
        ) : null;
    };

    useEffect(() => {
        if (frontImage !== "")
            handleCaptureFrontCamera()
        // eslint-disable-next-line
    }, [frontImage])
    const handleDelete = async (e) => {
        const {isConfirmed} = await Swal.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        })
        if (isConfirmed) {
            e.preventDefault()
            await api.delete(`/check/check/${id}/`)
                .then(() => {
                    toast.success("Check successfully deleted.", {theme: "colored"})
                    navigate(`/customer/${customerData.id}/detail`)
                })
                .catch(() => {
                    toast.error("An error occurred.", {theme: "colored"})
                })
        }
    };

    return (
        <Fragment>
            {loading ?
                <CustomSpinner
                    imageStyle={{top: 40, left: 30}}
                    width={180}
                    style={{zIndex: 9999}}
                /> :
                <Row className={"g-3"}>
                    <Col lg={12}>
                        <Card className={"mt-3"}>
                            <Card.Header>
                                <Flex justifyContent={"between"}>
                                    <h3>Edit Check for {customerData.fullname}</h3>
                                    <Flex justifyContent={"between"}>
                                        <Form.Group className={"me-2"}>
                                            <IconButton
                                                icon={"times"}
                                                className={"bg-danger"}
                                                iconClassName={"me-2"}
                                                onClick={(e) => handleDelete(e)}
                                            >
                                                <span className={"d-none d-xl-inline-block"}>Delete Check</span>
                                            </IconButton>
                                        </Form.Group>
                                        <Form.Group className={"me-2"}>
                                            <Button
                                                type="reset"
                                                className={"bg-secondary"}
                                                onClick={() => navigate(`/customer/${customerData.id}/detail`)}
                                            >
                                                Cancel
                                            </Button>
                                        </Form.Group>
                                        <Form.Group>
                                            {loading ?
                                                <CustomSpinner
                                                    imageStyle={{top: 40, left: 30}}
                                                    width={180}
                                                    style={{zIndex: 9999}}
                                                />
                                                :
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                >
                                                    Save
                                                </Button>
                                            }
                                        </Form.Group>
                                    </Flex>
                                </Flex>
                            </Card.Header>
                            <Formik initialValues={{}} onSubmit={handleSubmit}>
                                <Form>
                                    <Card.Body>
                                        <Row>
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Check Amount</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                <NumericFormat
                                                                    name={"amount"}
                                                                    placeholder={"Amount"}
                                                                    value={formData.amount}
                                                                    className={"form-control"}
                                                                    thousandSeparator={","}
                                                                    decimalScale={2}
                                                                    onChange={handleFieldChange}
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                            <FormError error={formErrors.amount}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Percentage</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text id="percent-addon1">%</InputGroup.Text>
                                                                <Form.Control
                                                                    type={"number"}
                                                                    name={"commission"}
                                                                    placeholder={"Percentage"}
                                                                    min={0}
                                                                    max={100}
                                                                    value={commission}
                                                                    onChange={handleFieldChange}
                                                                    aria-describedby={"percent-addon1"}
                                                                />
                                                            </InputGroup>
                                                            <FormError error={formErrors.commission}/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Commission</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                <NumericFormat
                                                                    name={"flat_commission"}
                                                                    placeholder={"Commission"}
                                                                    value={flatCommission}
                                                                    className={"form-control"}
                                                                    thousandSeparator={","}
                                                                    decimalScale={2}
                                                                    onChange={handleFieldChange}
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                            </InputGroup>
                                                            <FormError error={formErrors.flat_commission}/>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Net Payment</Form.Label>
                                                            <InputGroup>
                                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                <NumericFormat
                                                                    value={netPayment}
                                                                    className={"form-control"}
                                                                    disabled
                                                                    thousandSeparator={","}
                                                                    decimalScale={2}
                                                                />
                                                            </InputGroup>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>Date</Form.Label>
                                                            <DatePicker
                                                                selected={date}
                                                                onChange={(date) => {
                                                                    setDate(date);
                                                                }}
                                                                formatWeekDay={day => day.slice(0, 3)}
                                                                className='form-control'
                                                                dateFormat="MM-dd-yyyy hh:mma"
                                                                placeholderText="MM-DD-YYYY"
                                                                showTimeSelect
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group>
                                                            <Form.Label>Company</Form.Label>
                                                            <Select
                                                                onInputChange={(e, actionMeta) => handleCompanyInputChange(e, actionMeta)}
                                                                name={"company"}
                                                                options={companyOptions}
                                                                value={companyValue}
                                                                isLoading={companyLoading}
                                                                components={{Menu, NoOptionsMessage}}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter') {
                                                                        handleAddNew();
                                                                    }
                                                                }}
                                                                menuIsOpen={menuIsOpen}
                                                                onMenuOpen={() => setMenuIsOpen(true)} // Add this prop to open the menu
                                                                onMenuClose={() => setMenuIsOpen(false)}
                                                                onChange={(value) => {
                                                                    setCompanyValue(value)
                                                                    setFormData({...formData, company: value.value})
                                                                }}
                                                            />
                                                            <FormError error={formErrors.company}/>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                {companyForm &&
                                                    <>
                                                        <Row>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Company Name</Form.Label>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        name={"company_name"}
                                                                        placeholder={"Name"}
                                                                        value={companyFormData.company_name}
                                                                        onChange={handleCompanyChange}
                                                                    />
                                                                    <FormError error={formErrors.company_name}/>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col md={6}>
                                                                <Form.Group>
                                                                    <Form.Label>Company Phone</Form.Label>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        name={"company_phone"}
                                                                        placeholder={"(555) 555-5555"}
                                                                        value={companyFormData.company_phone}
                                                                        onChange={handleNumberChange}
                                                                    />
                                                                    <FormError error={formErrors.company_phone}/>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Form.Group>
                                                                    <Form.Label>Company Address</Form.Label>
                                                                    <Form.Control
                                                                        type={"text"}
                                                                        name={"company_address"}
                                                                        placeholder={"Address"}
                                                                        value={companyFormData.company_address}
                                                                        onChange={handleCompanyChange}
                                                                    />
                                                                    <FormError error={formErrors.company_address}/>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Dropzone
                                                        onDrop={acceptedFiles => onDropOnFrontImageDropzone(acceptedFiles)}>
                                                        {({getRootProps, getInputProps}) => (
                                                            <>
                                                                <>
                                                                    {frontCheckFiles.length > 0 ?
                                                                        <div className="mt-3">
                                                                            {frontCheckFiles.map(file => (
                                                                                <Flex
                                                                                    alignItems="center"
                                                                                    justifyContent={"center"}
                                                                                    className="py-3 border-bottom btn-reveal-trigger"
                                                                                    key={file.path}
                                                                                    style={{maxHeight: "300px"}}
                                                                                >
                                                                                    <img
                                                                                        className={"img-thumbnail"}
                                                                                        src={file.preview}
                                                                                        style={{
                                                                                            objectFit: "cover",
                                                                                            height: "300px",
                                                                                            width: "100%"
                                                                                        }}
                                                                                        alt={file.path}/>
                                                                                </Flex>
                                                                            ))}
                                                                        </div>
                                                                        :
                                                                        <div {...getRootProps({className: 'dropzone-area py-6'})}>
                                                                            <input {...getInputProps()} disabled/>
                                                                            <Flex justifyContent="center">
                                                                                <p className="fs-0 mb-0 text-700">
                                                                                    Check preview in here
                                                                                </p>
                                                                            </Flex>
                                                                        </div>
                                                                    }
                                                                </>
                                                                <FormError error={formErrors.front_image}/>
                                                                <Flex
                                                                    justifyContent={frontCheckFiles.length > 0 ? 'between' : "center"}
                                                                    className={"mt-2"}>
                                                                    {frontCheckFiles.length > 0 &&
                                                                        <IconButton
                                                                            variant="danger"
                                                                            size="sm"
                                                                            icon="times"
                                                                            transform="shrink-3"
                                                                            onClick={() => handleFrontFileRemove(frontCheckFiles[0].path)}
                                                                        >
                                                                        <span
                                                                            className="d-none d-sm-inline-block ms-1">Remove</span>
                                                                        </IconButton>
                                                                    }
                                                                    <IconButton
                                                                        variant="success"
                                                                        size="sm"
                                                                        icon="camera"
                                                                        transform="shrink-3"
                                                                        onClick={() => setCameraFrontImageModal(true)}
                                                                    >
                                                                        <span className="d-none d-sm-inline-block ms-1">Take Photo</span>
                                                                    </IconButton>
                                                                </Flex>
                                                            </>
                                                        )}
                                                    </Dropzone>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Modal show={cameraFrontImageModal} onHide={handleCloseFrontCameraModal}
                                           size={"lg"}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Camera</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Container>
                                                <Flex justifyContent={"center"} alignItems={"center"} wrap={"wrap"}>
                                                    {frontImage === '' ?
                                                        <Webcam
                                                            audio={false}
                                                            ref={frontImageWebcamRef}
                                                            screenshotFormat="image/jpeg"
                                                            className={"ms-0 p-0"}
                                                            style={{
                                                                maxHeight: "300px", // Set maximum height to full viewport height
                                                                minHeight: "300px",
                                                                minWidth: "700px",
                                                                maxWidth: "100vw", // Set maximum width to full viewport width
                                                                objectFit: "cover", // Maintain aspect ratio and fill available space
                                                            }}
                                                            videoConstraints={videoConstraints}
                                                        />
                                                        : <img src={frontImage} alt={""}/>}
                                                    <Button variant="primary" onClick={changeCamera}
                                                            className={"fs-0 p-2 pt-0 pb-0 mt-2 ms-2"}
                                                    >
                                                        <i
                                                            className={"fa fa-camera-rotate fa-xl mt-3 mb-3 me-2"}
                                                            style={{
                                                                color: "white",
                                                                cursor: "pointer",
                                                            }}
                                                        ></i><br/>Switch Camera
                                                    </Button>
                                                </Flex>
                                            </Container>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseFrontCameraModal}>
                                                Cancel
                                            </Button>
                                            <Button variant="primary" className={"me-3"}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        captureFrontImage()
                                                    }}
                                            >
                                                Capture
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </Form>
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            }
        </Fragment>
    )
}

export default EditCheck