import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {
    Card,
    Col,
    Modal,
    Row,
    Button,
    CloseButton,
    Form,
    ListGroup
} from "react-bootstrap";
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import {useParams} from "react-router";
import useQuery from "../../../hooks/useQuery";
import {api} from "../../../utils/api";
import IconButton from "../../../components/common/IconButton";
import Flex from "../../../components/common/Flex";
import {Formik} from "formik";
import {toast} from "react-toastify";
import FormError from "../../errors/FormError";
import {useNavigate} from "react-router-dom";
import {FaHashtag} from "react-icons/fa";
import {
    CustomSpinner, formatCheckDateTime,
    formatDate
} from "../../../helpers/utils"
import SoftBadge from "../../../components/common/SoftBadge";
import AppContext from "../../../context/Context";
import {Swiper, SwiperSlide} from "swiper/react";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import {Navigation, Pagination} from "swiper";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/styles.css";
import Avatar from "../../../components/common/Avatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faHashtag, faMapMarkerAlt, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import defaultAvatar from "../../../assets/img/logos/user.png"
import Divider from "../../../components/common/Divider";
import axios from "axios";
import {buildEposXml} from "../../../utils/jsonToXML";

const CustomerDetail = () => {
    const [loading, setLoading] = useState(false)
    const [checkCount, setCheckCount] = useState(0)
    const [amountTotal, setAmountTotal] = useState(0)
    const [commissionTotal, setCommissionTotal] = useState(0)
    const [noteIndex] = useState(0);
    const [customerDetails, setCustomerDetails] = useState({})
    const [customerAvatars, setCustomerAvatars] = useState([])
    const [customerDriverLicences, setCustomerDriverLicences] = useState([])
    const [customerChecks, setCustomerChecks] = useState([])
    const [customerNotes, setCustomerNotes] = useState([])
    const [selectedNotes, setSelectedNotes] = useState([])
    const [modalNoteShow, setModalNoteShow] = useState(false)
    const [noteFormData, setNoteFormData] = useState({description: null})
    const [activeSlideIndex, setActiveSlideIndex] = useState(0)
    const [errors, setErrors] = useState({})
    const [expandedNotes, setExpandedNotes] = useState([]);
    const [noteCount, setNoteCount] = useState(0)
    const [lightboxAvatarOpen, setLightboxAvatarOpen] = useState(false);
    const [lightboxDriverLicensesOpen, setLightboxDriverLicensesOpen] = useState(false);
    const [lightboxChecksOpen, setLightboxChecksOpen] = useState(false);
    const [avatarIndex, setAvatarIndex] = useState(0)
    const [driverLicenseIndex, setDriverLicenseIndex] = useState(0)
    const navigate = useNavigate()
    const {id} = useParams()
    const query = useQuery()
    const {
        config: {isAuthenticated, printer_ip},
        setConfig
    } = useContext(AppContext);

    query.set("customer_id", id)

    const getCustomerDetails = async () => {
        setLoading(true)
        await api.get(`/customer/customer/${id}/`).then(res => {
            setCustomerDetails(res.data)
        })
            .catch(err => {
                if (err.response.status === 404)
                    navigate("/errors/404")
                else if (err.response.status === 404) {
                    setConfig("isAuthenticated", !isAuthenticated)
                }
            })
        setLoading(false)
    }

    const getCustomerAvatars = async () => {
        setLoading(true)
        query.set("hidden", "false")
        const data = (await api.get(`/customer/avatar/?${query.toString()}`)).data
        setCustomerAvatars([])
        data?.results?.forEach(item => {
            setCustomerAvatars(prevState => [
                ...prevState,
                {
                    preview: item.avatar,
                    object: item,
                    src: item.avatar,
                    width: 1080 * 4,
                    height: 720 * 4,
                }
            ])
        })
    }

    const getCustomerDriverLicences = async () => {
        setLoading(true)
        query.set("hidden", "false")
        const data = (await api.get(`/customer/driverlicence/?${query.toString()}`)).data
        setCustomerDriverLicences([])
        data?.results?.forEach(item => {
            setCustomerDriverLicences(prevState => [
                ...prevState,
                {
                    preview: item.image,
                    object: item,
                    src: item.image,
                    width: 2560 * 4,
                    height: 1440 * 4,
                }
            ])
        })
    }

    const getCustomerChecks = async () => {
        setLoading(true)
        const data = (await api.get(`/check/check/?${query.toString()}`)).data
        setCustomerChecks([])
        data?.results?.forEach(item => {
            setCustomerChecks(prevState => [
                ...prevState,
                {
                    preview: item.front_image,
                    object: item,
                    src: item.front_image,
                    width: 5120 * 4,
                    height: 2880 * 4,
                }
            ])
        })
        setCheckCount(data.count)
        setAmountTotal(data.total_amount ? data.total_amount : 0)
        setCommissionTotal(data.total_commission_amount ? data.total_commission_amount : 0)
        setLoading(false)
    }

    const getCustomerNotes = async () => {
        const data = (await api.get(`/customer/note/?${query.toString()}`)).data
        setCustomerNotes(data.results)
        setNoteCount(data.count)
        setLoading(false)
    }

    useEffect(() => {
        getCustomerDetails()
        getCustomerAvatars()
        getCustomerDriverLicences()
        getCustomerChecks()
        getCustomerNotes()
        // eslint-disable-next-line
    }, [])

    const handleNoteFormChange = (e) => {
        setNoteFormData({...noteFormData, description: e.target.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!noteFormData.description) {
            toast.error("Please enter a description for the note.", {theme: "colored"});
            return;
        }
        let noteData = new FormData()
        noteData.append("description", noteFormData.description)
        noteData.append("customer", id)
        await api.post("/customer/note/", noteData).then(() => {
            toast.success("Note successfully added.", {theme: "colored"})
            getCustomerNotes()
            setModalNoteShow(!modalNoteShow)
        })
            .catch(err => {
                setErrors(err.response.data)
                toast.error("Failed to add note.", {theme: "colored"})
            })
        setNoteFormData({})
    }

    useEffect(() => {
        // Update the selected notes when customerNotes or noteIndex changes
        const startIndex = noteIndex * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const selectedNotes = customerNotes.slice(startIndex, endIndex);
        setSelectedNotes(selectedNotes);
    }, [customerNotes, noteIndex]);

    const itemsPerPage = 4;
    const printCheck = async (checkDetails) => {
        setLoading(true);
        const url = `${window.location.protocol}//${printer_ip}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`;

        const printData = `<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/"><s:Body>${buildEposXml(checkDetails?.receipt_data)}</s:Body></s:Envelope>`;
        console.log(printData)
        try {
            await axios.post(url, printData, {
                headers: {
                    'Content-Type': 'text/xml; charset=utf-8',
                },
                timeout: 10000 // Timeout set to 10000 milliseconds (10 seconds)
            });
            toast.success("Check printed successfully.", {theme: "colored"});
        } catch (error) {
            toast.error("Error printing the check: " + error.toString(), {theme: "colored"});
        } finally {
            setLoading(false);
        }
    };

    const handleSlideChange = (swiper) => {
        setActiveSlideIndex(swiper.activeIndex);
        // You can fetch the check information here based on the active slide index or the check ID
        // and update the state or set the values directly in the card header
    };

    const handleIsScammer = async (e) => {
        e.preventDefault()
        await api.patch(`/customer/customer/${id}/`, {is_scammer: !customerDetails.is_scammer})
        getCustomerDetails()
    };

    const toggleDescription = (noteId) => {
        setExpandedNotes((prevExpandedNotes) => {
            if (prevExpandedNotes.includes(noteId)) {
                return prevExpandedNotes.filter((id) => id !== noteId);
            } else {
                return [...prevExpandedNotes, noteId];
            }
        });
    };

    const listItemStyle = {
        position: 'relative',
        width: "100%",
        overflow: "hidden",
        textOverflow: 'ellipsis',
        wordWrap: "break-word",
        wordBreak: "break-all",
    };

    const viewMoreStyle = {
        position: 'absolute',
        bottom: 0,
        right: 0,
        background: 'transparent',
        padding: '0.5em',
        cursor: 'pointer',
        color: "#2c7be5"
    };
    let swiperRef = useRef();
    let avatarSwiperRef = useRef()
    let driverLicenseRef = useRef()
    return (
        <Fragment>
            {loading ?
                <CustomSpinner
                    imageStyle={{top: 40, left: 30}}
                    width={180}
                />
                :
                <Row className={"ms-0"}>
                    <Col lg={6} className={"m-0 p-0"}>
                        <Card className={"mt-3 ms-3 me-3"}>
                            <Card.Body className={"m-0 p-0"}>
                                <Flex justifyContent={"center"} alignItems={"center"}
                                      className={`flex-wrap flex-xl-nowrap g-0 m-0 p-0 ${customerDetails.is_scammer && "bg-danger"}`}
                                      style={{backgroundColor: "#677df3", borderRadius: "0.375rem"}}>
                                    {customerAvatars.length > 0 ? (
                                        <Swiper
                                            style={{
                                                maxWidth: "270px",
                                                minWidth: "270px",
                                                maxHeight: "270px",
                                                minHeight: "270px",
                                                overflow: "hidden",
                                            }}
                                            className={"ms-4 mt-3 mb-3 me-3"}
                                            ref={avatarSwiperRef}
                                            onSlideChange={(swiper) => setAvatarIndex(swiper.activeIndex)}
                                        >
                                            {customerAvatars.map((avatar) => (
                                                <SwiperSlide
                                                    onClick={() => setLightboxAvatarOpen(true)}
                                                    style={{
                                                        cursor: "pointer",
                                                        overflow: "hidden",
                                                        maxWidth: "270px",
                                                        maxHeight: "270px",
                                                    }}
                                                >
                                                    <img
                                                        src={avatar.preview}
                                                        className="d-block rounded-soft img-thumbnail shadow-sm"
                                                        style={{
                                                            objectFit: "cover",
                                                            width: "270px",
                                                            height: "270px",
                                                            maxWidth: "270px",
                                                            maxHeight: "270px"
                                                        }}
                                                        alt={avatar.customer_name}
                                                    />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    ) : (
                                        <Flex justifyContent="center">
                                            <Avatar
                                                src={defaultAvatar}
                                                style={{
                                                    objectFit: "cover",
                                                }}
                                                size={"6xl"}
                                                rounded={"soft"}
                                                className="mt-2 mb-2 ms-0 "
                                                mediaClass={"img-thumbnail shadow-sm"}
                                                alt=""
                                            />
                                        </Flex>
                                    )}
                                    <Lightbox
                                        carousel={{finite: true}}
                                        open={lightboxAvatarOpen}
                                        on={{
                                            view: ({index}) => setAvatarIndex(index)
                                        }}
                                        index={avatarIndex}
                                        close={() => {
                                            setLightboxAvatarOpen(false)
                                            avatarSwiperRef.current.swiper.slideTo(avatarIndex);
                                        }}
                                        slides={customerAvatars}
                                        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
                                    />
                                    <div className={"flex-fill flex-nowrap mt-3 ms-3 me-3"}
                                         style={{lineHeight: "25px"}}>
                                        <p className={"fs-2 mt-0 mb-0 pt-0 pb-0 fw-bold text-white text-wrap"}>
                                            {customerDetails.fullname}
                                        </p>
                                        <Divider className={"mt-0 mb-0 pt-0 pb-0"}/>
                                        <p className={"fs-1 text-white"}>
                                            <FontAwesomeIcon icon={faMapMarkerAlt}/> {customerDetails.address}
                                        </p>
                                        <p className={"fs-1 text-white"}>
                                            <FontAwesomeIcon icon={faPhoneAlt}/> {customerDetails.phone}
                                        </p>
                                        <p className={"fs-1 text-white"}>
                                            <FontAwesomeIcon icon={faHashtag}/> {customerDetails.credential_id}
                                        </p>
                                        <p className={"fs-1 text-white"}>
                                            <strong><FontAwesomeIcon
                                                icon={faCalendarAlt}/></strong> {formatDate(customerDetails.birth_date)}
                                        </p>
                                    </div>

                                </Flex>
                                <Flex justifyContent={"center"} alignItems={"center"}
                                      className={`g-0 m-0 p-0 flex-wrap flex-xl-nowrap`}>
                                    {customerDriverLicences.length > 0 ?
                                        <Swiper
                                            style={{
                                                width: "340px",
                                            }}
                                            className={"ms-4 mt-3 mb-3 me-0 rounded-soft"}
                                            ref={driverLicenseRef}
                                            onSlideChange={(swiper) => setDriverLicenseIndex(swiper.activeIndex)}
                                        >
                                            {customerDriverLicences.map((avatar => (
                                                <SwiperSlide onClick={() => setLightboxDriverLicensesOpen(true)}
                                                             style={{
                                                                 cursor: "pointer",
                                                                 overflow: "hidden",
                                                                 display: "flex",
                                                                 justifyContent: "center",
                                                                 alignItems: "center",
                                                                 paddingTop: "20px",
                                                                 paddingBottom: "20px"
                                                             }}
                                                             className={"rounded-soft"}
                                                >
                                                    <img src={avatar.preview}
                                                         className="d-block rounded-soft img-thumbnail shadow-sm"
                                                         style={{
                                                             objectFit: "cover",
                                                             height: "150px",
                                                             maxWidth: "150px",
                                                             minWidth: "150px",
                                                             scale: "3"
                                                         }}
                                                         alt={avatar.customer_name}/>
                                                </SwiperSlide>
                                            )))
                                            }
                                        </Swiper>
                                        :
                                        <Flex justifyContent={"center"}>
                                            <div>There is no driver license or passport photo.</div>
                                        </Flex>
                                    }
                                    <Lightbox
                                        carousel={{finite: true}}
                                        open={lightboxDriverLicensesOpen}
                                        index={driverLicenseIndex}
                                        on={{
                                            view: ({index}) => setDriverLicenseIndex(index)
                                        }}
                                        close={() => {
                                            setLightboxDriverLicensesOpen(false)
                                            driverLicenseRef.current.swiper.slideTo(driverLicenseIndex);
                                        }}
                                        slides={customerDriverLicences}
                                        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
                                    />
                                    <div className={"flex-fill mt-2 ms-3 me-3"}>
                                        <Flex justifyContent="between" wrap={"wrap"} className={"mb-3"}>
                                            <IconButton
                                                variant="secondary"
                                                onClick={handleIsScammer}
                                                size="sm"
                                                icon="bell"
                                                className={`mb-1 ${customerDetails.is_scammer && "bg-danger"}`}
                                                shake={customerDetails.is_scammer}
                                                cursor="pointer"
                                            >
                                                        <span
                                                            className="d-none d-xl-inline-block ms-1">{customerDetails.is_scammer ? "Remove" : "Create"} alert</span>
                                            </IconButton>
                                            <IconButton
                                                variant="primary"
                                                onClick={() => navigate(`/customer/${id}/edit`)}
                                                size="sm"
                                                icon="edit"
                                                transform="shrink-4"
                                                className="mb-1"
                                            >
                                                <span className="d-none d-xl-inline-block ms-1">Edit</span>
                                            </IconButton>
                                        </Flex>
                                        <Flex justifyContent={"between"} wrap={"wrap"}
                                              className={"mt-2 mb-0 pt-0 pb-0"}>
                                            <div className={"text-nowrap mt-0 mb-0 pt-0 pb-0"}>
                                                <FaHashtag className={"p-0"} color={"#4e52ef"}/>
                                                &nbsp; Count Checks:
                                            </div>
                                            <div className={"text-end mt-0 mb-0 pt-0 pb-0 mb-1"}>
                                                <strong>{checkCount}</strong>
                                            </div>
                                        </Flex>
                                        <Divider className={"mt-0 mb-0 pt-0 pb-0"}/>
                                        <Flex justifyContent={"between"} wrap={"wrap"}
                                              className={"mt-0 mb-0 pt-0 pb-0"}>
                                            <div className={"text-nowrap mt-0 mb-0 pt-0 pb-0"}>
                                                <i className="fa fa-sharp fa-light fa-comment-dollar mt-1 p-0"
                                                   style={{color: "#4e52ef"}}></i>&nbsp;
                                                Commission:
                                            </div>
                                            <div className={"text-end mt-0 mb-0 pt-0 pb-0 mb-1"}>
                                                <strong>${commissionTotal}</strong>
                                            </div>
                                        </Flex>
                                        <Divider className={"mt-0 mb-0 pt-0 pb-0"}/>
                                        <Flex justifyContent={"between"} wrap={"wrap"}
                                              className={"mt-0 mb-0 pt-0 pb-0"}>
                                            <div className={"text-nowrap mt-0 mb-0 pt-0 pb-0"}>
                                                <i className="fa fa-sharp fa-light fa-money-check-dollar mt-0 p-0"
                                                   style={{color: "#4e52ef"}}></i>
                                                &nbsp;Total Checks:
                                            </div>
                                            <div className={"text-end d-flex flex-wrap mt-0 mb-0 pt-0 pb-0 mb-0"}>
                                                <strong>${amountTotal}</strong>
                                            </div>
                                        </Flex>
                                    </div>
                                </Flex>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Row className={"g-0"}>
                            <Card className={"mt-3"}>
                                <Card.Header>
                                    <Row>
                                        <Col md={12}>
                                            <Flex justifyContent={"end"}>
                                                <IconButton
                                                    size="sm"
                                                    icon={"edit"}
                                                    className={"me-2 top-1 bg-white border-0"}
                                                    iconClassName={"text-primary"}
                                                    onClick={(e) => navigate(`/check/${customerChecks[activeSlideIndex]?.object?.id}/edit`)}
                                                >
                                                </IconButton><IconButton

                                                size="sm"
                                                icon={"print"}
                                                className={"me-2 top-1 bg-white border-0"}
                                                iconClassName={"text-primary"}
                                                onClick={() => printCheck(customerChecks[activeSlideIndex]?.object)}
                                            >
                                            </IconButton>
                                                <IconButton
                                                    size="sm"
                                                    icon={"plus"}
                                                    transform="shrink-3"
                                                    className={"me-2 end-2 top-1"}
                                                    onClick={(e) => navigate(`/check/${id}/new`)}
                                                >
                                                    <span className={"d-none d-xl-inline-block"}>Check</span>
                                                </IconButton>
                                            </Flex>
                                        </Col>

                                        <Col md={6}>
                                            <strong>Date: </strong> {customerChecks[activeSlideIndex]?.object?.created_at ? formatCheckDateTime(customerChecks[activeSlideIndex]?.object?.created_at) : "N/D"}
                                        </Col>
                                        <Col md={2} style={{padding: 0}}>
                                            <Flex justifyContent={"start"}>
                                                <strong>Amount: &nbsp;</strong> ${customerChecks[activeSlideIndex]?.object?.formatted_amount ? customerChecks[activeSlideIndex]?.object?.formatted_amount : 0}
                                            </Flex>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <strong>Commission: </strong> ${customerChecks[activeSlideIndex]?.object?.commission_amount ? customerChecks[activeSlideIndex]?.object?.commission_amount : 0}
                                        </Col>
                                        <Col md={6} style={{padding: 0}}>
                                            <Flex justifyContent={"start"}>
                                                <strong>Net
                                                    Payment: &nbsp;</strong> ${customerChecks[activeSlideIndex]?.object?.net_payment ? customerChecks[activeSlideIndex]?.object?.net_payment : 0}
                                            </Flex>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    {customerChecks.length > 0 ?
                                        <Swiper
                                            ref={swiperRef}
                                            onSlideChange={handleSlideChange}
                                            pagination={{clickable: true}}
                                            navigation={true}
                                            modules={[Pagination, Navigation]}
                                        >
                                            {customerChecks.map((check => (
                                                <SwiperSlide key={check.id}
                                                             style={{
                                                                 cursor: "pointer",
                                                                 display: "flex",
                                                                 alignItems: "center",
                                                                 justifyContent: "center",
                                                             }}
                                                             onClick={() => setLightboxChecksOpen(true)}
                                                >
                                                    <img src={check.preview} className="d-block img-thumbnail"
                                                         style={{
                                                             objectFit: "cover",
                                                             height: "300px",
                                                             width: "100%"
                                                         }}
                                                         alt={check.customer_name}/>
                                                </SwiperSlide>
                                            )))}
                                        </Swiper>
                                        :
                                        <Flex justifyContent={"center"}>
                                            <div className={"mt-5 mb-5"}>There is no check added.</div>
                                        </Flex>
                                    }
                                    <Lightbox
                                        carousel={{finite: true}}
                                        open={lightboxChecksOpen}
                                        on={{
                                            view: ({index}) => {
                                                setActiveSlideIndex(index)
                                            }
                                        }}
                                        index={activeSlideIndex}
                                        close={() => {
                                            setLightboxChecksOpen(false)
                                            swiperRef.current.swiper.slideTo(activeSlideIndex);
                                        }}
                                        slides={customerChecks}
                                        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
                                    />
                                </Card.Body>
                            </Card>
                        </Row>
                        <Row className={"g-0"}>
                            <Card className={"mt-3"}>
                                <Card.Header>
                                    <Row>
                                        <Col md={8}>
                                            <h3 className={"position-absolute top-1 start-2"}>Notes</h3>
                                        </Col>
                                        <Col md={4}>
                                            <Flex justifyContent='end'>
                                                <IconButton
                                                    variant="primary"
                                                    size="sm"
                                                    icon="plus"
                                                    transform="shrink-3"
                                                    className={"me-2 end-2 position-absolute top-1"}
                                                    onClick={() => setModalNoteShow(true)}
                                                >
                                                    <span className="d-none d-sm-inline-block ms-1">Note</span>
                                                </IconButton>
                                            </Flex>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body className={"mt-0"}>
                                    {customerNotes.length > 0 ? (
                                        <div>
                                            <ListGroup className={"mb-3"}>
                                                {selectedNotes.map((note) => (
                                                    <ListGroup.Item key={note.id} className={"me-2"}>
                                                        <Row>
                                                            <Col md={9}>
                                                                <Flex
                                                                    style={{
                                                                        ...listItemStyle,
                                                                        maxHeight: expandedNotes.includes(note.id) ? 'none' : '3em',
                                                                    }}
                                                                    justifyContent={"start"}
                                                                    wrap={"wrap"}
                                                                >
                                                                    {note.description}
                                                                </Flex>
                                                            </Col>
                                                            <Col md={3} style={{padding: 0}}>
                                                                {note.description.length > 3 * 16 && (
                                                                    <div style={viewMoreStyle}
                                                                         onClick={() => toggleDescription(note.id)}>
                                                                        {expandedNotes.includes(note.id) ? 'Show Less' : 'Show More'}
                                                                    </div>
                                                                )}
                                                                <Flex justifyContent={"end"}>
                                                                    <SoftBadge pill bg="primary" className="me-2">
                                                                        {note.user_fullname}
                                                                    </SoftBadge>
                                                                </Flex>
                                                            </Col>
                                                        </Row>
                                                        <Flex
                                                            justifyContent={"start"}>{formatDate(note.created_at)}</Flex>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                            <AdvanceTablePagination
                                                length={customerNotes.length}
                                                itemsPerPage={4}
                                                query={query}
                                                fetch={getCustomerNotes}
                                                count={noteCount}
                                            />
                                        </div>
                                    ) : (
                                        <div className={"mb-3 mt-4"}>There is no note.</div>
                                    )}

                                    <Modal
                                        show={modalNoteShow}
                                        onHide={() => setModalNoteShow(false)}
                                        size="lg"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                    >
                                        <Modal.Header>
                                            <Modal.Title id="contained-modal-title-vcenter">Add note</Modal.Title>
                                            <CloseButton
                                                className="btn btn-circle btn-sm transition-base p-0"
                                                onClick={() => setModalNoteShow(false)}
                                            />
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Formik initialValues={noteFormData} onSubmit={handleSubmit}>
                                                <Form>
                                                    <Form.Label>Description</Form.Label>
                                                    <textarea
                                                        name={"description"}
                                                        onChange={handleNoteFormChange}
                                                        value={noteFormData.description}
                                                        className={"form-control"}
                                                        placeholder={"Write a note"}
                                                        rows={5}
                                                    />
                                                    <FormError error={errors.description}/>
                                                </Form>
                                            </Formik>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant={"secondary"} onClick={() => {
                                                setModalNoteShow(false)
                                                setNoteFormData({description: null})
                                            }}>Close</Button>
                                            {loading ?
                                                <CustomSpinner
                                                    style={{
                                                        top: 50,
                                                        left: 300,
                                                        position: "absolute",
                                                    }}
                                                    width={200}
                                                    spinnerClass={"top-50 position-absolute"}
                                                />
                                                :
                                                <Button
                                                    onClick={(e) => handleSubmit(e)}
                                                >Save</Button>
                                            }
                                        </Modal.Footer>
                                    </Modal>
                                </Card.Body>
                            </Card>
                        </Row>
                    </Col>
                </Row>
            }
        </Fragment>
    )
}
export default CustomerDetail