import React, {Fragment, useContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import AppContext from "../../../context/Context";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import {Formik} from "formik";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import FormError from "../../errors/FormError";
import {CustomSpinner} from "../../../helpers/utils";

const EditCompany = () => {
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({
        name: "",
        address: "",
        phone: "",
    })

    const {id} = useParams()
    const navigate = useNavigate()
    const {
        config: {isAuthenticated},
        setConfig
    } = useContext(AppContext);

    const getCompanyInformation = async () => {
        setLoading(true);
        await api.get(`/company/${id}/`)
            .then(res => {
                setFormData(res.data);
                setLoading(false);
            })
            .catch(err => {
                if (err.response.status === 404)
                    navigate("/errors/404")
                else if (err.response.status === 403) {
                    setConfig("isAuthenticated", !isAuthenticated)
                    setConfig("user", JSON.stringify({}))
                }
            })
        setLoading(false);
    }

    const handleFieldChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        await api.patch(`/company/${id}/`, formData)
            .then(() => {
                toast.success(`Company successfully updated.`, {
                    theme: 'colored'
                });
                setLoading(false);
                navigate("/company");
            })
            .catch(err => {
                setErrors(err.response.data)
                setLoading(false);
                toast.error(`Failed to update company.`, {
                    theme: 'colored'
                });
            })
    }

    useEffect(() => {
        getCompanyInformation()
        // eslint-disable-next-line
    }, [])

    const handleNumberChange = (e) => {
        const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Fragment>
            {loading ?
                <CustomSpinner
                    imageStyle={{top: 40, left: 30}}
                    width={180}
                /> :
                <Formik initialValues={formData} onSubmit={handleSubmit}>
                    <Form onSubmit={handleSubmit}>
                        <Row className={"g-3"}>
                            {!loading &&
                                <Col lg={12}>
                                    <Card className={"mt-3"}>
                                        <Card.Header>
                                            <h3>Company information</h3>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Name:</Form.Label>
                                                        <Form.Control
                                                            placeholder={"Name"}
                                                            name={"name"}
                                                            type={"text"}
                                                            value={formData.name}
                                                            onChange={handleFieldChange}
                                                        />
                                                        <FormError error={errors.name}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Address:</Form.Label>
                                                        <Form.Control
                                                            placeholder={"Address"}
                                                            name={"address"}
                                                            type={"text"}
                                                            value={formData.address}
                                                            onChange={handleFieldChange}
                                                        />
                                                        <FormError error={errors.address}/>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Phone:</Form.Label>
                                                        <Form.Control
                                                            placeholder={"(555) 555-5555"}
                                                            name={"phone"}
                                                            type={"text"}
                                                            value={formData.phone}
                                                            onChange={handleNumberChange}
                                                        />
                                                        <FormError error={errors.phone}/>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Form.Group>
                                                {loading ?
                                                    <CustomSpinner
                                                        imageStyle={{top: 40, left: 30}}
                                                        width={180}
                                                    />
                                                    :
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="mt-3 w-100"
                                                    >
                                                        Save
                                                    </Button>
                                                }
                                            </Form.Group>
                                        </Card.Footer>
                                    </Card>
                                </Col>}
                        </Row>
                    </Form>
                </Formik>
            }

        </Fragment>
    )
}

export default EditCompany