import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Card, Dropdown} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import {api} from "../../utils/api";
import useQuery from "../../hooks/useQuery";
import {CustomSpinner, formatDate} from "../../helpers/utils";
import CompaniesTableHeader from "./CompaniesTableHeader";
import AppContext from "../../context/Context";
import CardDropdown from "../../components/common/CardDropdown";
import debounce from "lodash/debounce";
import axios from "axios";
import {Link} from "react-router-dom";

const Companies = () => {
    const [loading, setLoading] = useState(false)
    const [companiesData, setCompaniesData] = useState([])
    const [count, setCount] = useState(0)
    const [length, setLength] = useState(0)
    const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    let query = useQuery()
    const {
        config: {isAuthenticated},
        setConfig
    } = useContext(AppContext);

    let cancelTokenSource = null;
    const fetchCompanies = async (query) => {
        setLoading(true);

        // Get the sorting parameter from the state
        const sortingParam = `${sortingDirection === 'desc' ? '-' : ''}${sortingField}`;

        // Cancel the previous request if it exists
        if (cancelTokenSource) {
            cancelTokenSource.cancel("New request triggered");
        }

        // Create a new cancel token source
        cancelTokenSource = axios.CancelToken.source();

        await api.get(`/company/?${query.toString()}`, {
            params: {
                ordering: sortingParam,
            },
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                setCompaniesData(res.data?.results);
                setCount(res.data?.count);
            })
            .catch(err => {
                if (err.response.status === 403) {
                    setConfig("isAuthenticated", !isAuthenticated);
                    setConfig("user", JSON.stringify({}));
                }
            });

        setLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchCompanies, 600), []);

    useEffect(() => {
        fetchCompanies(query)
        setLength(companiesData.length)
        // eslint-disable-next-line
    }, [sortingField, sortingDirection])

    useEffect(() => {
        return () => {
            if (cancelTokenSource) {
                cancelTokenSource.cancel("Component unmounted");
            }
        };
    }, [cancelTokenSource]);

    const columns = [
        {
            accessor: 'name',
            Header: 'Name',
            headerProps: {className: 'ps-5'},
            cellProps: {
                className: 'ps-5'
            },
            canSort: true,
            sortingDirection: sortingField === "name" && sortingDirection,
            isSorted: false,
        },
        {
            accessor: 'address',
            Header: 'Address',
            headerProps: {className: 'ps-5'},
            cellProps: {className: 'ps-5'},
            canSort: true,
            sortingDirection: sortingField === "address" && sortingDirection,
            isSorted: false,
        },
        {
            accessor: 'annotated_customers_count',
            Header: 'Customers',
            headerProps: {className: 'ps-5 text-center'},
            cellProps: {className: 'ps-5 text-center'},
            canSort: true,
            sortingDirection: sortingField === "annotated_customers_count" && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {annotated_customers_count, name} = rowData.row.original;
                return (
                    <Link to={`/customer?company=${name}`}>{annotated_customers_count}</Link>
                )
            }
        },
        {
            accessor: 'annotated_checks_count',
            Header: 'Checks',
            headerProps: {className: 'ps-5 text-center'},
            cellProps: {className: 'ps-5 text-center'},
            canSort: true,
            sortingDirection: sortingField === "annotated_checks_count" && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {annotated_checks_count, name} = rowData.row.original;
                return (
                    <Link to={`/report?company=${name}`}>{annotated_checks_count}</Link>
                )
            }
        },
        {
            accessor: 'phone',
            Header: 'Phone',
            headerProps: {className: 'ps-5'},
            cellProps: {className: 'ps-5'},
            canSort: true,
            sortingDirection: sortingField === "phone" && sortingDirection,
            isSorted: false,
        },
        {
            accessor: 'created_at',
            Header: 'Creation Date',
            headerProps: {className: 'ps-5'},
            cellProps: {className: 'ps-5'},
            canSort: true,
            sortingDirection: sortingField === "created_at" && sortingDirection,
            isSorted: false,
            Cell: rowData => {
                const {created_at} = rowData.row.original;
                return (
                    formatDate(created_at)
                )
            },
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: (rowData) => {
                const {id} = rowData.row.original;
                return (
                    <CardDropdown>
                        <div className="py-2">
                            <Dropdown.Item href={`/company/${id}/edit`}>Edit</Dropdown.Item>
                        </div>
                    </CardDropdown>
                );
            }
        }
    ];

    const data = companiesData && companiesData.map(customer => (
        {
            id: customer.id,
            name: customer.name,
            address: customer.address,
            phone: customer.phone,
            annotated_customers_count: customer.annotated_customers_count,
            annotated_checks_count: customer.annotated_checks_count,
            created_at: customer.created_at,
        }
    ))

    const handleSort = (column) => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={data}
            pagination
            perPage={20}
        >
            <Card className="mb-3">
                <Card.Header>
                    <CompaniesTableHeader table count={count} fetchCompanies={delayedLoadItems}/>
                </Card.Header>
                <Card.Body className="p-0">
                    {loading ?
                        <CustomSpinner
                            imageStyle={{top: 40, left: 30}}
                            width={180}
                        />
                        :
                        <AdvanceTable
                            table
                            handleSort={handleSort}
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                size: 'sm',
                                striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                    }
                </Card.Body>
                <Card.Footer>
                    <AdvanceTablePagination
                        query={query}
                        fetch={fetchCompanies}
                        count={count}
                        length={length}
                        itemsPerPage={20}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    );
};

export default Companies;
