export const version = '4.1.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  isFluid: true,
  isRTL: false,
  isDark: false,
  isAuthenticated: false,
  navbarPosition: 'top',
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
  navbarStyle: 'transparent',
  group: "",
  user_id: "",
  store_commission: "",
  avatar: "",
  avatarCustomerCameraId: "",
  driverLicenceCustomerCameraId: "",
  newCheckCameraId: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
