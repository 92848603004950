import React, {useContext} from 'react';
import {Col, Nav, Row} from 'react-bootstrap';
import NavbarNavLink from "./NavbarNavLink";
import AppContext from "../../../context/Context";

const NavbarTopDropDownMenus = ({routes}) => {
    const {
        config: {group},
    } = useContext(AppContext);

    return (
        <Row>
            {routes.children.map(route => route.groups.some(name => name === group) && (
                <Col>
                    <Nav className="flex-column">
                        <NavbarNavLink key={route.name} route={route}/>
                    </Nav>
                </Col>
            ))}
        </Row>
    );
};

export default NavbarTopDropDownMenus;
