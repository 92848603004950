import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import HttpsRedirect from 'react-https-redirect';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <HttpsRedirect>
            <Main>
                <App/>
            </Main>
        </HttpsRedirect>
    </React.StrictMode>
);
