import React, {useContext} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';

import Settings from '../pages/Users/settings/Settings';
import Customers from '../pages/Customers/Customers';
import Users from '../pages/Users/Users';

import Error404 from '../pages/errors/Error404';
import CardLogin from '../pages/authentication/card/Login';
import Logout from '../pages/authentication/card/Logout';
import Store from "../pages/Store/Store";
import Report from "../pages/CheckReport/Report";
import NewCustomer from "../pages/Customers/NewCustomer/NewCustomer";
import EditCustomer from "../pages/Customers/EditCustomer/EditCustomer";
import CustomerDetail from "../pages/Customers/CustomerDetail/CustomerDetail";
import NewCheck from "../pages/CheckReport/NewCheck/NewCheck";
import NewStore from "../pages/Store/NewStore/NewStore";
import EditStore from "../pages/Store/EditStore/EditStore";
import NewUser from "../pages/Users/NewUser/NewUser";
import EditUser from "../pages/Users/EditUser/EditUser";
import AppContext from "../context/Context";
import Companies from "../pages/Companies/Companies";
import EditCompany from "../pages/Companies/EditCompany/EditCompany";
import PrintReport from "../pages/CheckReport/PrintReport/PrintReport";
import EditCheck from "../pages/CheckReport/EditCheck/EditCheck";

const FalconRoutes = () => {
    const { config: {group}} = useContext(AppContext);

    return (
        <Routes>
            <Route path="/login" element={<CardLogin/>}/>
            <Route exact path="/" element={<Navigate to="/login" replace/>}/>
            <Route element={<ErrorLayout/>}>
                <Route path="errors/404" element={<Error404/>}/>
            </Route>
            {/*- ------------- Authentication ---------------------------  */
            }
            <Route path="/logout" element={<Logout/>}/>

            {/*- ------------- Split ---------------------------  */
            }

            {/* //--- MainLayout Starts  */
            }

            <Route element={<MainLayout/>}>
                <Route path="/company" element={<Companies/>}/>
                <Route path="/company/:id/edit" element={<EditCompany/>}/>
                <Route path="/customer" element={<Customers/>}/>
                <Route path="/customer/new" element={<NewCustomer/>}/>
                <Route path="/customer/:id/detail" element={<CustomerDetail/>}/>
                <Route path="/customer/:id/edit" element={<EditCustomer/>}/>
                <Route path="/check/:id/new" element={<NewCheck/>}/>
                <Route path="/check/:id/edit" element={<EditCheck/>}/>
                <Route path="/report" element={<Report/>}/>
                <Route path="/report/print" element={<PrintReport/>}/>
                {group === process.env.REACT_APP_ADMIN_GROUP_NAME &&
                    <>
                        <Route path="/user" element={<Users/>}/>
                        <Route path="/user/new" element={<NewUser/>}/>
                        <Route path="/user/:id/edit" element={<EditUser/>}/>
                        <Route path="/store" element={<Store/>}/>
                        <Route path="/store/new" element={<NewStore/>}/>
                        <Route path="/store/:id/edit" element={<EditStore/>}/>
                    </>
                }
                {/*Pages*/}
                <Route path="/settings" element={<Settings/>}/>

            </Route>
            {/*- ------------- Card ---------------------------  */
            }


            {/* <Navigate to="/errors/404" /> */
            }
            <Route path="*" element={<Navigate to="/errors/404" replace/>}/>
        </Routes>
    )
        ;
};

export default FalconRoutes;
