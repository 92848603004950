import React from 'react';
import PropTypes from 'prop-types';
import {Card, Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import {Draggable} from 'react-beautiful-dnd';

const TaskCard = ({task, id, index, kanbanItems, setKanbanItems}) => {
    const handleRemoveTaskCard = () => {
        console.log(index);
        const newData = [...kanbanItems];
        newData[0].elements = newData[0]?.elements.filter((_, idx) => idx !== index);
        setKanbanItems(newData);
    };
    // styles we need to apply on draggables
    const getItemStyle = (isDragging) => ({
        cursor: isDragging ? 'grabbing' : 'pointer',
        transform: isDragging ? 'rotate(-2deg)' : ''
    });

    return (
        <Draggable key={`task-${id}`} draggableId={`task-${id}`} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                    className="kanban-item"
                >
                    <Card
                        style={getItemStyle(snapshot.isDragging)}
                        className="kanban-item-card hover-actions-trigger"
                    >
                        <Card.Body>
                            <Dropdown
                                onClick={e => e.stopPropagation()}
                                align="end"
                                className="font-sans-serif"
                            >
                                <Dropdown.Toggle
                                    variant="falcon-default"
                                    size="sm"
                                    className="kanban-item-dropdown-btn hover-actions dropdown-caret-none"
                                >
                                    <FontAwesomeIcon icon="ellipsis-h" transform="shrink-2"/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="py-0" align={'end'}>

                                    <Dropdown.Item onClick={handleRemoveTaskCard} className="text-danger">
                                        Remove
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <div className="mb-0 fw-medium font-sans-serif">{task?.text}</div>
                            <div className="kanban-item-footer cursor-default">
                                <div className="text-500 ">
                                    <SoftBadge key={'satus'} bg={'info'} className="py-1 me-1 mb-1">
                                        {task?.type}
                                    </SoftBadge>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            )}
        </Draggable>
    );
};


TaskCard.propTypes = {
    kanbanId: PropTypes.any,
    task: PropTypes.any,
    setOpenTaskInformationModal: PropTypes.func,
    setSelectedTask: PropTypes.func,
    setTaskID: PropTypes.func,
    index: PropTypes.number,
    filters: PropTypes.any
};

export default TaskCard;
