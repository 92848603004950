import React, {useContext, useEffect} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';

const MainLayout = () => {
    const {hash, pathname} = useLocation();
    const isKanban = pathname.includes('kanban');
    // const isChat = pathname.includes('chat');

    const {
        config: {navbarPosition, isAuthenticated},
    } = useContext(AppContext);

    useEffect(() => {
        setTimeout(() => {
            if (hash) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({block: 'start', behavior: 'smooth'});
                }
            }
        }, 0);
    }, [hash]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    if (!isAuthenticated) {
        return <Navigate to={'/login'} replace/>;
    }

    return (
        <div>

            <div className={'container-fluid'}>
                {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
                    <NavbarVertical />
                )}
                <div className={classNames('content', {'pb-0': isKanban})}>
                    <NavbarTop/>
                    {/*------ Main Routes ------*/}
                    <Outlet/>
                </div>
            </div>
        </div>
    );
};

export default MainLayout;
