import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Card, Dropdown} from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import {Link} from 'react-router-dom';
import Flex from 'components/common/Flex';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CustomersTableHeader from './CustomersTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import {api} from "../../utils/api";
import useQuery from "../../hooks/useQuery";
import Avatar from "../../components/common/Avatar";
import {CustomSpinner, formatDate} from "../../helpers/utils";
import AppContext from "../../context/Context";
import debounce from "lodash/debounce";
import axios from "axios";

const Customers = () => {
    const [loading, setLoading] = useState(false)
    const [customersData, setCustomersData] = useState([])
    const [count, setCount] = useState(0)
    const [length, setLength] = useState(0)
    const [sortingField, setSortingField] = useState('created_at'); // Set the initial sorting field
    const [sortingDirection, setSortingDirection] = useState('desc');
    const {
        config: {isAuthenticated}, setConfig
    } = useContext(AppContext);
    let query = useQuery()

    let cancelTokenSource = null;
    const fetchCustomers = async (query) => {
        setLoading(true)
        const sortingParam = `${sortingDirection === 'desc' ? '-' : ''}${sortingField}`;

        // Cancel the previous request if it exists
        if (cancelTokenSource) {
            cancelTokenSource.cancel("New request triggered");
        }

        // Create a new cancel token source
        cancelTokenSource = axios.CancelToken.source();
        await api.get(`/customer/customer/?${query.toString()}`, {
            params: {
                ordering: sortingParam,
            },
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                setCustomersData(res.data?.results)
                setCount(res.data?.count)
            })
            .catch(err => {
                if (err?.response?.status === 403) {
                    setConfig("isAuthenticated", !isAuthenticated)
                    setConfig("user", JSON.stringify({}))
                }
            })
        setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchCustomers, 600), []);

    useEffect(() => {
        fetchCustomers(query)
        setLength(customersData.length)
        // eslint-disable-next-line
    }, [sortingField, sortingDirection])

    useEffect(() => {
        return () => {
            if (cancelTokenSource) {
                cancelTokenSource.cancel("Component unmounted");
            }
        };
    }, [cancelTokenSource]);

    const listItemStyle = {
        width: '170px',
        wordWrap: 'break-word',
        wordBreak: 'break-all',
        whiteSpace: 'pre-wrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
    };

    const columns = [{
        accessor: 'first_name',
        Header: 'Name',
        headerProps: {className: 'ps-5 fs-0'},
        cellProps: {
            className: 'ps-5 fs-0'
        },
        canSort: true,
        sortingDirection: sortingField === "first_name" && sortingDirection,
        isSorted: false,
        Cell: rowData => {
            const {first_name, avatar, id, birth_date} = rowData.row.original;
            return (<Link to={`/customer/${id}/detail`}>
                <Flex alignItems="center">
                    {avatar.img ? (<Avatar src={avatar.img} rounded={"soft"} size="4xl" className="me-2"/>) : (
                        <Avatar size="4xl" name={avatar.name} rounded={"soft"} className="me-2" />)}
                    <div className="flex-1">
                        <h6 className="mb-0 fs-0" style={{
                            width: '200px', wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'pre-wrap',
                        }}>{first_name}</h6>
                        <div style={{color: "black"}}>{formatDate(birth_date)}</div>
                    </div>
                </Flex>
            </Link>);
        }
    }, {
        accessor: 'address',
        Header: 'Address',
        headerProps: {className: 'ps-5 fs-0'},
        cellProps: {className: 'ps-5 fs-0'},
        canSort: true,
        sortingDirection: sortingField === "address" && sortingDirection,
        isSorted: false,
        Cell: rowData => {
            const {address} = rowData.row.original;
            return (<div
                style={{
                    width: '300px', wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'pre-wrap',
                }}
            >
                {address}
            </div>)
        }
    }, {
        accessor: 'phone',
        Header: 'Phone',
        headerProps: {className: 'ps-5 fs-0'},
        cellProps: {className: 'ps-5 fs-0'},
        canSort: true,
        sortingDirection: sortingField === "phone" && sortingDirection,
        isSorted: false,
    }, {
        accessor: 'last_customer_note',
        Header: 'Last Customer Note',
        headerProps: {className: 'ps-5 fs-0'},
        cellProps: {className: 'ps-5 fs-0'},
        Cell: rowData => {
            const {last_customer_note} = rowData.row.original;
            return (<Flex alignItems="center">
                <div
                    style={{
                        ...listItemStyle, maxHeight: '5em',
                    }}
                >
                    {last_customer_note}
                </div>
            </Flex>)
        }
    }, {
        accessor: 'created_at',
        Header: 'Creation Date',
        headerProps: {className: 'ps-5 fs-0'},
        cellProps: {className: 'ps-5 fs-0'},
        canSort: true,
        sortingDirection: sortingField === "created_at" && sortingDirection,
        isSorted: false,
        Cell: rowData => {
            const {created_at} = rowData.row.original;
            return (formatDate(created_at))
        }
    }, {
        accessor: 'none', Header: '', disableSortBy: true, cellProps: {
            className: 'text-end'
        }, Cell: (rowData) => {
            const {id} = rowData.row.original;
            return (<CardDropdown>
                <div className="py-2">
                    <Dropdown.Item href={`/customer/${id}/edit`}>Edit</Dropdown.Item>
                </div>
            </CardDropdown>);
        }
    }];

    const data = customersData && customersData.map(customer => ({
        id: customer.id,
        first_name: customer.fullname,
        avatar: {
            img: customer.last_customer_avatar && customer.last_customer_avatar
        },
        address: customer.address,
        phone: customer.phone,
        created_at: customer.created_at,
        last_customer_note: customer.last_customer_note,
        birth_date: customer.birth_date,
    }))

    const handleSort = (column) => {
        if (column.canSort) {
            const {id} = column;
            const isDescending = sortingField === id && sortingDirection === 'desc';

            // Update the sorting field and direction
            setSortingField(id);
            setSortingDirection(isDescending ? 'asc' : 'desc');

            // Modify the sorting properties of the column
            column.isSorted = true;
            column.isSortedDesc = isDescending;
            column.sortingDirection = isDescending ? 'asc' : 'desc';
        }
    };

    return (
        <AdvanceTableWrapper
        columns={columns}
        data={data}
        pagination
        perPage={20}
    >
        <Card className="mb-3">
            <Card.Header>
                <CustomersTableHeader table fetchCustomers={delayedLoadItems} count={count}/>
            </Card.Header>
            <Card.Body className="p-0">
                {loading ?
                    <CustomSpinner
                        imageStyle={{top: 40, left: 30}}
                        width={180}
                    />
                    : <AdvanceTable
                        handleSort={handleSort}
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm', striped: true, className: 'fs--1 mb-0 overflow-hidden',
                        }}
                        headers={columns}
                    />}
            </Card.Body>
            <Card.Footer>
                <AdvanceTablePagination
                    query={query}
                    fetch={fetchCustomers}
                    count={count}
                    length={length}
                    itemsPerPage={20}
                />
            </Card.Footer>
        </Card>
    </AdvanceTableWrapper>);
};

export default Customers;
