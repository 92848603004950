/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import {Form} from 'react-bootstrap';
import {
    useTable,
    usePagination,
    useRowSelect,
    useGlobalFilter
} from 'react-table';

export const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, className, ...rest}, ref) => {
        const defaultRef = React.useRef();

        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <Form.Check
                type="checkbox"
                className={classNames('form-check fs-0 mb-0', className)}
            >
                <Form.Check.Input type="checkbox" ref={resolvedRef} {...rest} />
            </Form.Check>
        );
    }
);

const AdvanceTableWrapper = ({
                                 children,
                                 columns,
                                 data,
                                 selection,
                                 selectionColumnWidth,
                                 pagination,
                                 perPage
                             }) => {
    const {
        getTableProps,
        headers,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        nextPage,
        previousPage,
        setPageSize,
        gotoPage,
        pageCount,
        state: {pageIndex, pageSize, selectedRowIds, globalFilter},
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: {pageSize: pagination ? perPage : (data.length ? data.length : 2000)}
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        hooks => {
            if (selection) {
                hooks.visibleColumns.push(columns => [
                    {
                        id: 'selection',
                        Header: ({getToggleAllRowsSelectedProps}) => (
                            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        headerProps: {
                            style: {
                                width: selectionColumnWidth
                            }
                        },
                        cellProps: {
                            style: {
                                width: selectionColumnWidth
                            }
                        },
                        Cell: ({row}) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        )
                    },
                    ...columns
                ]);
            }
        }
    );

    const recursiveMap = children => {
        return React.Children.map(children, child => {
            if (child.props?.children) {
                return React.cloneElement(child, {
                    children: recursiveMap(child.props.children)
                });
            } else {
                if (child.props?.table) {
                    return React.cloneElement(child, {
                        ...child.props,
                        getTableProps,
                        headers,
                        page,
                        prepareRow,
                        canPreviousPage,
                        canNextPage,
                        nextPage,
                        previousPage,
                        gotoPage,
                        pageCount,
                        pageIndex,
                        selectedRowIds,
                        pageSize,
                        setPageSize,
                        globalFilter,
                        setGlobalFilter
                    });
                } else {
                    return child;
                }
            }
        });
    };

    return (
        <>{recursiveMap(children)}</>
    );
};

export default AdvanceTableWrapper;
