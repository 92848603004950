import React, {Fragment, useContext, useEffect, useState} from "react";
import {Formik} from "formik";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import FormError from "../../errors/FormError";
import Select from "react-select";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import Dropzone from "react-dropzone";
import Flex from "../../../components/common/Flex";
import IconButton from "../../../components/common/IconButton";
import cloudUpload from "../../../assets/img/icons/cloud-upload.svg";
import {useParams} from "react-router";
import AppContext from "../../../context/Context";
import {CustomSpinner} from "../../../helpers/utils";

const EditUser = () => {
    const [groupValue, setGroupValue] = useState({})
    const [storeValue, setStoreValue] = useState({})
    const [errors, setErrors] = useState({})
    const [groups, setGroups] = useState([])
    const [stores, setStores] = useState([])
    const [avatarFiles, setAvatarFiles] = useState([]);
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: "",
        first_name: "",
        last_name: "",
        store: "",
        printer_ip: "",
        groups: []
    })

    const {id} = useParams()
    const navigate = useNavigate()
    const {
        config: {isAuthenticated},
        setConfig
    } = useContext(AppContext);

    const fetchUser = async () => {
        setLoading(true)
        await api.get(`/account/user/${id}/`)
            .then(res => {
                const data = res.data
                setFormData(data)
                data.avatar && setAvatarFiles([{
                    path: data.avatar,
                    preview: data.avatar
                }])
                setStoreValue({value: data.store, label: data.store_name})
                setGroupValue({value: data.groups[0], label: data.groups_names[0]})
            })
            .catch(err => {
                if (err.response.status === 404)
                    navigate("/errors/404")
                else if (err.response.status === 404) {
                    setConfig("isAuthenticated", !isAuthenticated)
                }
            })
        setLoading(false)
    }

    const fetchGroups = async () => {
        const data = (await api.get("/account/auth/group/")).data
        setGroups(data.results)
    }

    const fetchStores = async () => {
        const data = (await api.get("/store/store/")).data
        setStores(data.results)
    }

    useEffect(() => {
        fetchUser()
        fetchGroups()
        fetchStores()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (groupValue.label === process.env.REACT_APP_ADMIN_GROUP_NAME) {
            setStoreValue('')
            setFormData({...formData, store: ""})
        }
    }, [formData, groupValue, storeValue])

    const groupOptions = groups.map(group => (
        {value: group.id, label: group.name}
    ))

    const storeOptions = stores.map(store => (
        {value: store.id, label: store.name}
    ))

    const handleFieldChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleSubmit = async () => {
        api.defaults.headers["Content-Type"] = 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
        setLoading(true)
        let userData = new FormData()
        if (avatarFiles[0] && avatarFiles[0] instanceof File)
            userData.append("avatar", avatarFiles[0])
        userData.append("username", formData.username)
        if (formData.password)
            userData.append("password", formData.password)
        userData.append("first_name", formData.first_name)
        userData.append("last_name", formData.last_name)
        userData.append("email", formData.email)
        if (formData.printer_ip)
            userData.append("printer_ip", formData.printer_ip);
        if (formData.store)
            userData.append("store", formData.store)
        userData.append("groups", groupValue.value)
        await api.patch(`/account/user/${id}/`, userData)
            .then(() => {
                setLoading(false)
                toast.success("User successfully updated.", {theme: "colored"})
                navigate("/user")
            })
            .catch(err => {
                setLoading(false)
                setErrors(err.response.data)
                toast.error("Failed to update user.", {theme: "colored"})
            })
    }

    const handleAvatarFileRemove = path => {
        setAvatarFiles(avatarFiles.filter(file => file.path !== path));
    };

    const onDropOnAvatarDropzone = (acceptedFiles) => {
        setAvatarFiles(
            acceptedFiles.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            )
        )
    }

    return (
        <Fragment>
            {loading ?
                <CustomSpinner
                    imageStyle={{top: 40, left: 30}}
                    width={180}
                /> :
                <Formik initialValues={formData} onSubmit={handleSubmit}>
                    <Form onSubmit={handleSubmit} autoComplete={false}>
                        <Row className={"g-3"}>
                            <Col lg={3}>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h3>Photo</h3>
                                    </Card.Header>
                                    <Card.Body>
                                        <Dropzone onDrop={acceptedFiles => onDropOnAvatarDropzone(acceptedFiles)}>
                                            {({getRootProps, getInputProps}) => (
                                                <>
                                                    {avatarFiles.length > 0 ?
                                                        <div className="mt-3">
                                                            {avatarFiles.map(file => (
                                                                <>
                                                                    <img style={{width: "100%", height: "100%"}}
                                                                         src={file.preview}
                                                                         alt={file.path}/>
                                                                    <Flex justifyContent={"center"}>
                                                                        <IconButton
                                                                            variant="danger"
                                                                            icon={"times"}
                                                                            onClick={() => {
                                                                                handleAvatarFileRemove(file.path)
                                                                            }}
                                                                            className="mt-2 position-relative"
                                                                        >
                                                                            Remove
                                                                        </IconButton>
                                                                    </Flex>
                                                                </>
                                                            ))}
                                                        </div>
                                                        :
                                                        <div {...getRootProps({className: 'dropzone-area py-6'})}>
                                                            <input {...getInputProps()} />
                                                            <Flex justifyContent="center">
                                                                <img src={cloudUpload} alt="" width={25}
                                                                     className="me-2"/>
                                                                <p className="fs-0 mb-0 text-700">Add an image here</p>
                                                            </Flex>
                                                        </div>
                                                    }
                                                    <FormError error={errors.avatar}/>
                                                </>
                                            )}
                                        </Dropzone>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={9}>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h3>User information</h3>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col md={6}>
                                                <Row>
                                                    <Form.Group>
                                                        <Form.Label>Username:</Form.Label>
                                                        <Form.Control
                                                            placeholder={"Username"}
                                                            name={"username"}
                                                            type={"text"}
                                                            value={formData.username}
                                                            onChange={handleFieldChange}
                                                        />
                                                        <FormError error={errors.username}/>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group>
                                                        <Form.Label>First Name:</Form.Label>
                                                        <Form.Control
                                                            placeholder={"First Name"}
                                                            name={"first_name"}
                                                            type={"text"}
                                                            value={formData.first_name}
                                                            onChange={handleFieldChange}
                                                        />
                                                        <FormError error={errors.first_name}/>
                                                    </Form.Group>
                                                </Row>
                                            </Col>
                                            <Col md={6}>
                                                <Row>
                                                    <Form.Group>
                                                        <Form.Label>Last Name:</Form.Label>
                                                        <Form.Control
                                                            placeholder={"Last Name"}
                                                            name={"last_name"}
                                                            type={"text"}
                                                            value={formData.last_name}
                                                            onChange={handleFieldChange}
                                                        />
                                                        <FormError error={errors.last_name}/>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Form.Group>
                                                        <Form.Label>Email:</Form.Label>
                                                        <Form.Control
                                                            type={"email"}
                                                            name={"email"}
                                                            placeholder={"Email"}
                                                            value={formData.email}
                                                            onChange={handleFieldChange}
                                                        />
                                                        <FormError error={errors.email}/>
                                                    </Form.Group>
                                                </Row>
                                            </Col>
                                            <Col md={4}>
                                                <Row>
                                                    <Form.Group>
                                                        <Form.Label>Password:</Form.Label>
                                                        <Form.Control
                                                            placeholder={"Password"}
                                                            name={"password"}
                                                            type={"password"}
                                                            value={formData.password}
                                                            onChange={handleFieldChange}
                                                        />
                                                        <FormError error={errors.password}/>
                                                    </Form.Group>
                                                </Row>
                                            </Col>
                                            <Col md={4}>
                                                <Row>
                                                    <Form.Group>
                                                        <Form.Label>Group:</Form.Label>
                                                        <Select
                                                            name={"groups"}
                                                            placeholder={"Group"}
                                                            options={groupOptions}
                                                            value={groupValue}
                                                            onChange={value => {
                                                                setGroupValue(value)
                                                                setFormData({...formData, groups: [value.value]})
                                                            }}
                                                        />
                                                        <FormError error={errors.groups}/>
                                                    </Form.Group>
                                                </Row>
                                            </Col>
                                            {groupValue && groupValue.label === process.env.REACT_APP_STORE_GROUP_NAME &&
                                                <Col md={4}>
                                                    <Row>
                                                        <Form.Group>
                                                            <Form.Label>Store:</Form.Label>
                                                            <Select
                                                                name={"store"}
                                                                placeholder={"Select a store"}
                                                                options={storeOptions}
                                                                classNamePrefix="react-select"
                                                                value={storeValue}
                                                                onChange={value => {
                                                                    setStoreValue(value)
                                                                    setFormData({...formData, store: value.value})
                                                                }}
                                                            />
                                                            <FormError error={errors.store}/>
                                                            <FormError error={errors ? errors[0] : ""}/>
                                                        </Form.Group>
                                                    </Row>
                                                </Col>
                                            }
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <Row>
                                                        <Form.Group>
                                                        <Form.Label>Printer IP:</Form.Label>
                                                        <Form.Control
                                                            placeholder={"IP"}
                                                            name={"printer_ip"}
                                                            type={"text"}
                                                            value={formData?.printer_ip}
                                                            onChange={handleFieldChange}
                                                        />
                                                        <FormError error={errors?.printer_ip}/>
                                                    </Form.Group>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Form.Group>
                                            {loading ?
                                                <CustomSpinner
                                                    imageStyle={{top: 40, left: 30}}
                                                    width={180}
                                                />
                                                :
                                                <Button
                                                    onClick={handleSubmit}
                                                    type="submit"
                                                    color="primary"
                                                    className="mt-3 w-100"
                                                    disabled={!formData.username}
                                                >
                                                    Save
                                                </Button>
                                            }
                                        </Form.Group>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Formik>
            }
        </Fragment>
    )
}

export default EditUser